/*
3.PAGES
├── 3.1. SPORT COMMON ELEMENTS
│   ├── 3.1.1. SPORT SEARCH INPUT
│   ├── 3.1.2. SPORT BRANCH PICKER
│   └── 3.1.3. SPORT FILTERS POPUP PANEL
├── 3.2. PREMATCH PAGE
│   └── 3.2.1. PREMATCH PAGE FILTER TOP PANEL
└── 3.3. LIVEMATCH PAGE
    ├── 3.3.1. LIVE PAGE FILTER TOP PANEL
    └── 3.3.2. LIVE SEARCH RESULT DROPDOWN
*/

/*
|--------------------------------------------------------------------------
| == 3.1.1. SPORT SEARCH INPUT
|--------------------------------------------------------------------------
*/

.sport-search {
    @extend %G-sport-search;

    .sport-search__container {
        border-color: #515151;
        background-color: #2D2D2D;

        .sport-search__icon,
        .dx-placeholder {
            color: #DDDDDD;
        }

        .sport-search__icons--clear .sport-search__icon,
        .dx-texteditor-input {
            color: #FFFFFF;
        }
    }

    &.sport-search--menu {
        background-color: #2d2d2d;
    }

    .dx-texteditor-input,
    input.dx-texteditor-input:-webkit-autofill {
        color: #fff !important;
        -webkit-text-fill-color: #fff !important;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2. SPORT BRANCH PICKER
|--------------------------------------------------------------------------
*/

/* ----------------- Mobile Branch Picker ----------------- */

.branch-picker--mobile {
    @extend %G-branch-picker--mobile;

    .ll-branch-picker__dropdown-wrapper--mobile {
        background-color: #393939;
        color: #fff;
    }

    .ll-branch-picker__dropdown-wrapper--accent {
        color: #FF6D1E;
    }

    .ll-branch-picker__dropdown {
        background-color: #2D2D2D;
        color: #fff;
    }

    .ll-dropdown-menu-item--active {
        color: #FF6D1E;
    }
}

/* -------------- Tablet Desktop Branch Picker ---------------- */

.branch-picker--tablet,
.branch-picker--desktop {
    @extend %G-branch-picker--tablet--desktop;

    color: #fff;

    .branch-picker__branch-item {
        &.active .branch-picker__branch-item-label {
            background-color: #CD5B1D;
        }
    }

    .branch-picker__branch-item-label {
        background-color: #637858;
    }

    .slider__arrow {
        color: #7C7C7C;
    }
}

.branch-picker--tablet {
    @extend %G-branch-picker--tablet;
}

.branch-picker--desktop {
    @extend %G-branch-picker--desktop;
}

.body--with-cursor {
    .branch-picker {
        .slider__arrow:hover {
            color: #2D2D2D;
        }

        .branch-picker__branch-item:hover {
            .branch-picker__branch-item-label {
                background-color: #CD5B1D;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.3. SPORT FILTERS POPUP PANEL
|--------------------------------------------------------------------------
*/

.pre-filters-panel {
    @extend %G-pre-filters-panel;

    color: #fff;

    .event__search-highlite {
        text-decoration-color: #CD5B1D;
    }

    /* ---------------------- Filters ---------------------- */
    .filters-panel__filters-submit-btn {
        background: #CD5B1D;
        color: #fff;
    }
}

.pre-filters-panel--mobile,
.pre-filters-panel--tablet {
    @extend %G-pre-filters-panel--mobile--tablet;
}

.pre-filters-panel--mobile {
    height: $size-height__page-content-100vh_mobile;

    background-color: #272727;

    .filters-panel__header {
        background: #393939;
    }

    .pre-filters-panel__loader {
        background: #27272785;
    }

    .filters-panel__item {
        &:not(:last-of-type) {
            border-bottom: 1px solid #393939;
        }
    }

    /* ---------------------- Search ---------------------- */
    .sport-search__sport-item {
        background-color: #39393980;
        border-right: 1px solid #272727;
    }

    .sport-search__sport-count {
        background-color: #7C7C7C;
    }

    .sport-search__sport-item--active {
        background-color: #393939;

        .sport-search__sport-count {
            background-color: #CD5B1D;
        }
    }

    /* ---------------------- Filters Date Range Picker ---------------------- */
    .ll-date-range-picker--sport-filters {
        .ll-date-range-picker__picker-box {
            background-color: #393939;
            color: #FFFFFF;
        }

        .ll-date-range-picker__picker-input {
            color: #FFFFFF;
        }
    }

    /* ---------------------- Filters ---------------------- */
    .filters-panel__add-filter-button {
        background-color: #393939;
        color: #759D61;
    }

    .filters-panel__selected-filter {
        background-color: #393939;
        color: #FF6D1E;
    }

    .filters-panel__selected-filter-remove-btn {
        background-color: #7C7C7C;
        color: #393939;
    }

    .filters-panel-menu {
        border: 1px solid #393939;
        background-color: #2D2D2D;
    }

    .filters-panel-menu__header {
        background-color: #CD5B1D;
    }

    .filters-panel-menu__tabs {
        background-color: #393939;
    }

    .filters-panel-menu__tabs-item {
        &:not(:last-child):after {
            background-color: #707070;
        }

        &.active {
            color: #CD5B1D;
        }
    }

    .filters-panel-menu__item {
        .filters-panel__add-icon {
            color: #fff;
            border-color: #fff;
        }
    }

    .filters-panel-menu__item.selected {
        color: #FF6D1E;

        .filters-panel__add-icon {
            border-color: #FF6D1E;
            color: #FF6D1E;
        }

        .filters-panel-menu__item-count {
            background-color: #FF6D1E;
            color: #fff;
        }
    }

    .filters-panel-menu__item-count {
        background-color: #7C7C7C;
        color: #FFFFFF;
    }

    .filters-panel-menu__item--sub {
        color: #C5C5C5;
        background: #27272780;

        .filters-panel-menu__item-count {
            background-color: #C5C5C5;
            color: #2D2D2D;
        }
    }

    .filters-panel-menu__item--opened {
        background-color: #393939;
    }

    .filters-panel-menu__submit-btn-wrp {
        background-color: #272727E6;
    }

    .filters-panel__filters-result-clear-button {
        color: #DDDDDD;
    }

    .filters-panel__filters-result-tab {
        border: 1px solid #393939;
        color: #DDDDDD;

        &:not(:last-of-type) {
            border-right: none;
        }

        &.active {
            background: #393939;
            color: #FF6D1E;
        }
    }
}

.pre-filters-panel--tablet,
.pre-filters-panel--desktop {
    @extend %G-pre-filters-panel--tablet--desktop;

    background-color: #2D2D2D;

    .filters-panel__header {
        background: #393939;
    }

    .filters-panel__close-icon {
        color: #DDDDDD;
    }

    .pre-filters-panel__loader {
        background: #ffffff85;
    }

    .pre-filters-panel__container {
        border: 1px solid #393939;
        background: #2D2D2D;
        border-top: none;
    }

    .filters-panel__item {
        &:not(:last-of-type) {
            border-bottom: 1px solid #515151;
        }
    }

    .filters-panel__item-label {
        color: #fff;
    }

    /* ---------------------- Search ---------------------- */
    .sport-search {
        .sport-search__icons--clear .sport-search__icon {
            color: #7C7C7C;
        }

        .dx-texteditor-input,
        input.dx-texteditor-input:-webkit-autofill {
            color: #fff !important;
            -webkit-text-fill-color: #fff !important;
        }
    }

    .sport-search__sport-item {
        background-color: #515151;
        border-right: 1px solid #2D2D2D;
    }

    .sport-search__sport-count {
        background-color: #FFFFFF;
        color: #2D2D2D;
    }

    .sport-search__sport-item--active {
        background-color: #637858;

        .sport-search__sport-count {
            background-color: #CD5B1D;
            color: #fff;
        }
    }

    /* ---------------------- Filters Date Range Picker ---------------------- */
    .ll-date-range-picker--sport-filters {
        .ll-date-range-picker__picker-box {
            background-color: #393939;
            color: #fff;
        }

        .ll-date-range-picker__picker-input {
            color: #fff;
        }
    }

    /* ---------------------- Filters ---------------------- */
    .filters-panel__add-filter-button {
        background-color: #637858;
        color: #fff;
    }

    .filters-panel__selected-filter {
        background-color: #7C7C7C;
        color: #FFFFFF;
    }

    .filters-panel__selected-filter-arrow {
        color: #DADAD9;
    }

    .filters-panel__selected-filter-remove-btn {
        border: 1px solid #DDDDDD;
        background-color: #7C7C7C;
        color: #DDDDDD;
    }

    .filters-panel-menu {
        border: 1px solid #515151;
        background-color: #393939;
        color: #fff;
    }

    .filters-panel-menu__header {
        background-color: #CD5B1D;
        color: #fff;
    }

    .filters-panel-menu__header-close-icon {
        color: #DDDDDD;
    }

    .filters-panel-menu__tabs {
        background-color: #515151;
    }

    .filters-panel-menu__tabs-item {
        color: #fff;
        &:not(:last-child):after {
            background-color: #2D2D2D;
        }

        &.active {
            color: #CD5B1D;
        }
    }

    .filters-panel-menu__item {
        .filters-panel__add-icon {
            color: #bebebe;
            border-color: #bebebe;
        }
    }

    .filters-panel-menu__item.selected {
        color: #CD5B1D;

        .filters-panel__add-icon {
            border-color: #CD5B1D;
            color: #CD5B1D;
        }

        .filters-panel-menu__item-count {
            background-color: #CD5B1D;
            color: #fff;
        }
    }

    .filters-panel-menu__item-count {
        background-color: #7C7C7C;
        color: #FFFFFF;
    }

    .filters-panel-menu__item--sub {
        background: #2d2d2d;
    }

    .filters-panel-menu__item--opened {
        background-color: #7C7C7C;

        &.filters-panel-menu__item {
            color: #fff;
        }

        .filters-panel-menu__item-count {
            background-color: #fff;
            color: #7C7C7C;
        }
    }

    .filters-panel-menu__submit-btn-wrp {
        background-color: #2D2D2DE6;
    }

    .filters-panel__filters-result-header-text {
        color: #2D2D2D;
    }

    .filters-panel__filters-result-clear-button {
        color: #7C7C7C;
    }

    .filters-panel__filters-result-tab {
        border: 1px solid #7C7C7C;
        color: #2D2D2D;

        &:not(:last-of-type) {
            border-right: none;
        }

        &.active {
            background: #637858;
            color: #fff;
        }
    }
}

.pre-filters-panel--tablet {
    @extend %G-pre-filters-panel--tablet;
}

.pre-filters-panel--desktop {
    @extend %G-pre-filters-panel--desktop;

    .sport-search__sports-list-slider .slider__arrow {
        color: #7C7C7C;
    }
}

.body--with-cursor {
    .pre-filters-panel--tablet,
    .pre-filters-panel--desktop {
        .filters-panel__close-icon:hover {
            color: #fff;
        }

        .sport-search__icon:hover {
            color: #fff;
        }

        .sport-search__sport-item:hover {
            background-color: #637858;

            .sport-search__sport-count {
                background-color: #CD5B1D;
                color: #fff;
            }
        }

        .sport-search__sports-list-slider .slider__arrow:hover {
            color: #fff;
        }

        .ll-date-range-picker--sport-filters {
            .ll-date-range-picker__picker-box:hover {
                background-color: #515151;
            }
        }

        .filters-panel__add-filter-button:hover {
            background-color: #CD5B1D;
        }

        .filters-panel__selected-filter-remove-btn:hover {
            border-color: #fff;
            color: #fff;
        }

        .filters-panel__filters-submit-btn:not(.filters-panel__filters-submit-btn--disabled):hover {
            box-shadow: inset 0 0 1px 1px #CD5B1D;
            background: transparent;
            color: #CD5B1D;
        }

        .filters-panel-menu__header-close-icon:hover {
            color: #fff;
        }

        .filters-panel-menu__tabs-item:not(.disabled):hover {
            color: #CD5B1D;
        }

        .filters-panel-menu__item:not(.filters-panel-menu__item--opened):not(.filters-panel-menu__item-tournament) {
            .filters-panel-menu__item-content:hover {
                color: #CD5B1D;

                .filters-panel-menu__item-count {
                    background-color: #CD5B1D;
                }
            }
        }

        .filters-panel-menu__item-dropdown-arrow:hover,
        .filters-panel__add-icon:hover {
            border-color: #CD5B1D;
            color: #CD5B1D;
        }

        .filters-panel-menu__item:has(.filters-panel__add-icon:hover) {
            color: #CD5B1D;

            .filters-panel-menu__item-count {
                background-color: #CD5B1D;
            }
        }

        .filters-panel-menu__item--opened:hover {
            background-color: #393939;
            color: #fff;

            .filters-panel-menu__item-content {
                color: #fff;
            }
            .filters-panel-menu__item-count {
                background-color: #fff;
            }
        }

        .filters-panel__selected-filter--collapsable:hover {
            cursor: pointer;

            .filters-panel__selected-filter-arrow {
                color: #fff;
            }
        }

        .filters-panel__filters-result-clear-button:hover {
            color: #fff;
        }

        .filters-panel__filters-result-tab:hover {
            background: #637858;
            color: #fff;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.1. PREMATCH PAGE FILTER TOP PANEL
|--------------------------------------------------------------------------
*/

.prematch__filters--mobile {
    @extend %G-prematch-filters-mobile;
}

/*
|--------------------------------------------------------------------------
| == 3.3.1. LIVE PAGE FILTER TOP PANEL
|--------------------------------------------------------------------------
*/

.live-filters--mobile {
    @extend %G-live-filters--mobile;

    .live-filters__inner {
        background-color: #272727;
        color: #FFFFFF;
    }

    .live-filters__search {
        border-color: #393939;
    }

    .live-filters__switcher {
        background-color: #393939;
    }

    .live-filters__switcher-item {
        color: #fff;
        opacity: .4;

        &.live-filters__switcher-item--active {
            opacity: 1;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.3.2. LIVE SEARCH RESULT DROPDOWN
|--------------------------------------------------------------------------
*/

.sport-search__dropdown--live {
    background: #535353;
    color: #fff;

    .ll-dropdown-menu-item:not(:last-of-type) {
        border-bottom: 1px solid #888888;
    }
}

.body--with-cursor {
    .sport-search__dropdown--live .ll-dropdown-menu-item:hover {
        color: #CD5B1D;
    }
}