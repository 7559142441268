/*
4.FORMS
├── 4.1 LOGIN FORM
│   ├── 4.1.1 LOGIN FORM DESKTOP
│   └── 4.1.2 LOGIN FORM MOBILE
├── 4.2 FORGOT PASSWORD FORM
│   ├── 4.2.1 FORGOT PASSWORD MODAL FORM
│   └── 4.2.2 FORGOT PASSWORD PAGE FORM
├── 4.3 RESET PASSWORD FORM
│   ├── 4.3.1 RESET PASSWORD PAGE FORM
│   └── 4.3.2 RESET PASSWORD PAGE FORM
└── 4.4 CHANGE PASSWORD FORM
    ├── 4.4.1 CHANGE PASSWORD MODAL FORM
    └── 4.4.2 CHANGE PASSWORD PAGE FORM
*/

/*
| == 4.FORMS
|--------------------------------------------------------------------------
| == 4.1 LOGIN FORM
|--------------------------------------------------------------------------
| == 4.1.1 LOGIN FORM DESKTOP
|--------------------------------------------------------------------------
*/

.form__captcha-text {
    color: #bebebe;

    .form__captcha-link {
        color: #ff5a00;
    }
}

.login-form__signup-link {
    color: #FF6D1E;
}

.dx-texteditor.dx-editor-outlined.dx-state-hover {
    -webkit-box-shadow: inset 0 0 0 1px #dadad9;
    box-shadow: inset 0 0 0 1px #dadad9;
}

.ll-login-form-modal {
    .login-form__forgot-pwd-btn {
        color: #FF6D1E;
        font-size: 1.4rem;

        &:hover {
            text-decoration: underline;
            text-shadow: 0 0 7px #FF6D1E;
        }
    }

    .login-form__close-btn:hover {
        background: #637858;
        opacity: 0.7;
    }

    .login-form__error {
        color: #f44336;
        text-align: center;
    }

    .ll-input__text-box-container {
        &:focus-within {
            box-shadow: 0 0 8px 1px rgba(97, 192, 189, 0.5);
        }
    }

    .login-form--desktop {
        background-color: #393939;
        color: #fff;
        @extend %G-login-form;
        @extend %G-login-form--desktop;

        .login-form__login-btn {
            color: #fff;
        }
    }

    .ll-scrollbox--desktop {
        border-radius: 0 0 3px 3px;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.1.2 LOGIN FORM MOBILE
|--------------------------------------------------------------------------
*/

.login-form--tablet,
.login-form--mobile {
    @extend %G-login-form;

    color: #000;

    .login-form__error {
        color: #f44336;
        text-align: center;
    }

    .login-form__forgot-pwd-btn {
        text-align: center;
        color: #cd5b1d;

        &:hover {
            text-decoration: underline;
        }
    }

    .login-form__close-btn {
        border: 1px solid #cd5b1d;
        color: #cd5b1d;
    }

    .login-form__close-btn,
    .login-form__login-btn {
        text-align: center;
        text-transform: uppercase;
    }
    .ll-input__text-box-container {
        &:focus-within {
            box-shadow: 0 0 2px 1px rgba(97, 192, 189, 0.5);
        }

        input {
            color: #2d2d2d;
        }
    }
}

.login-drawer__logo {
    @extend %G-login-drawer__logo;

    background-image: url('../../../../static/themes/theme/images/logos/logo.png');
    background-size: cover;
}

/*
|--------------------------------------------------------------------------
| == 4.2 FORGOT PASSWORD FORM
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 4.2.1 FORGOT PASSWORD MODAL FORM
|--------------------------------------------------------------------------
*/

.forgot-password-form {
    @extend %G-forgot-password-form;
}

.ll-forgot-password-modal {
    .forgot-password-captcha--error,
    .forgot-password-captcha--error .dx-item-content {
        font-size: 1.2rem !important;
        color: red;
        text-align: center;
    }

    .ll-scrollbox--desktop {
        border-radius: 0 0 3px 3px;
        color: #fff;
        background: #393939;
    }
    .ll-input__text-box-container {
        &:focus-within {
            box-shadow: 0 0 2px 1px rgba(97, 192, 189, 0.5);
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 4.2.2 FORGOT PASSWORD PAGE FORM
|--------------------------------------------------------------------------
*/

.forgot-password--mobile,
.forgot-password--tablet {
    @extend %G-forgot-password--mobile;

    color: #000;
    background-color: #fff;

    .forgot-password__header {
        font-size: 2.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .ll-input__text-box-container {
        &:focus-within {
            box-shadow: 0 0 2px 1px rgba(97, 192, 189, 0.5);
        }

        input {
            color: #2d2d2d;
        }
    }
}

.forgot-password--tablet {
    @extend %G-forgot-password--tablet;
}

/*
|--------------------------------------------------------------------------
| == 4.3 RESET PASSWORD FORM
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 4.3.1 RESET PASSWORD FORM
|--------------------------------------------------------------------------
*/

.reset-password--desktop {
    @extend %G-reset-password--desktop;

    color: #fff;
    background: #393939;
    font-size: 0.9rem;

    .reset-password__header {
        font-size: 2.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .reset-password__wrong-code {
        font-size: 1.2rem;
        text-align: center;
        background: #fff;
    }

    .password-strength__label {
        text-align: left;
        font-size: 1.5rem;
    }

    .ll-input__text-box-container {
        &:focus-within {
            box-shadow: 0 0 2px 1px rgba(97, 192, 189, 0.5);
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 4.3.2 RESET PASSWORD PAGE FORM
|--------------------------------------------------------------------------
*/

.reset-password--tablet,
.reset-password--mobile {
    @extend %G-reset-password--mobile;
    color: #000;

    .reset-password__header {
        font-size: 1.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .reset-password__wrong-code {
        font-size: 1.2rem;
        text-align: center;
        background: #fff;
    }

    .password-strength__label {
        text-align: left;
        font-size: 1.2em;
    }

    .ll-input__text-box-container {
        input {
            color: #2d2d2d;
        }
    }

}


.reset-password--tablet {
    @extend %G-reset-password--tablet;

    border: 1px solid #d6d6d6;
    background: #f5f5f5;
}

/*
|--------------------------------------------------------------------------
| == 4.4 CHANGE PASSWORD FORM
|--------------------------------------------------------------------------
*/
.change-password {
    @extend %G-change-password;
}

/*
|--------------------------------------------------------------------------
| == 4.4.1 CHANGE PASSWORD MODAL FORM
|--------------------------------------------------------------------------
*/

.modal-change-password {
    .ll-input__text-box-container {
        &:focus-within {
            box-shadow: 0 0 2px 1px rgba(97, 192, 189, 0.5);
        }
    }

    .change-password__form-container {
        background-color: #fff;
    }

    &.modal-change-password--desktop {
        .ll-modal__scrollbox--desktop,
        .change-password__form-container {
            background: #393939;
        }

        .ll-btn--outlined {
            border-color: #bebebe;
            color: #bebebe;
        }

        color: #fff;
    }

    .change-password__header {
        font-size: 1.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .dx-invalid-message > .dx-overlay-content {
        font-size: 0.75em;
    }

    .password-strength__label {
        text-align: center;
        font-size: 1.8rem;
    }

    .password-strength__texts {
        color: #000;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.4.2 CHANGE PASSWORD PAGE FORM
|--------------------------------------------------------------------------
*/

.change-password--tablet,
.change-password--mobile {
    @extend %G-change-password--mobile;
    color: #000;
    background-color: #fff;

    .change-password__header {
        font-size: 2.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .password-strength__label {
        text-align: left;
        font-size: 1.2em;
    }
    .ll-input__text-box-container {
        &:focus-within {
            box-shadow: 0 0 2px 1px rgba(97, 192, 189, 0.5);
        }

        input {
            color: #2d2d2d;
        }
    }
}
/*
|--------------------------------------------------------------------------
| == 4.4.3(NEW) Google Authenticator
|--------------------------------------------------------------------------
*/

.google-auth__input {
    &:focus-within {
        box-shadow: 0 0 2px 1px rgba(97, 192, 189, 0.5);
    }
}