/*
3.PAGES
└── 3.11. MESSAGES PAGE
*/

/*
|--------------------------------------------------------------------------
| == 3.11. MESSAGES PAGE
|--------------------------------------------------------------------------
*/

.messages {
    .my-account__content {
        background-color: #272b30;
    }
}