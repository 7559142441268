.slots,
.casino {
    @extend %G-games;

    .games__header-menu-container {
        background-color: #393939;
    }

    .slots-casino-menu-wrapper {
        color: #fff;

        .games-menu__btn {
            background-color: #708e61;
        }
    }

    .games__item-status-icon--favorite {
        @extend %G-games-icon--favorite;
        color: #fff;
    }

    .games__item-status-icon--promoted {
        @extend %G-games-icon--promoted;
        background-color: #cd5b1d;
    }

    .games-icon--exclusive-wrp::before,
    .games-icon--exclusive-wrp::after {
        background: #cd5b1d;
    }

    .games-icon--exclusive-label {
        &::before {
            color: #fff;
        }
    }

    .games__item--without-image {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/slot-casino-without-img-big.png");
        color: #fff;
    }

    .game__provider-without-img {
        background: #393939;
        color: #fff;
        border: 1px solid #c1c1c1;
    }

    .game__game-name {
        background: #2d2d2d;
        color: #fff;
    }
}

.body--with-cursor {
    .games-menu__btn:hover {
        background-color: #cd5b1d;
    }
}

.slots--desktop,
.casino--desktop {
    @extend %G-games__desktop;

    .slots-casino--loader-container__desktop {
        height: calc(
            100vh - #{$size-height--header-upper-part_desktop + px} - #{$size-height--footer_desktop} - #{$size-height--content-footer}
        );
    }

    .slots-casino-menu__item:hover,
    .slots-casino-menu__item--active {
        color: #779866;
    }

    .games__search {
        border: 1px solid #ffffff50;

        .ll-input__textbox {
            input {
                color: #fff;
            }
        }
    }

    .games__list {
        min-height: calc(#{$size-max-height--page-container_desktop} - 300px - 90px);
    }

    .games__item {
        height: $size-height--new-thumbnail-game_desktop + px;
        width: $size-width--new-thumbnail-game_desktop + px;
        margin: $size-margin--new-thumbnail-game_desktop + px;
    }

    .games-icon--exclusive-wrp {
        @extend %G-games-icon--exclusive-wrp__desktop;
        .games-icon--exclusive-label {
            background: linear-gradient(
                -45deg,
                transparent calc(50% - 0.1rem),
                #cd5b1d 50%,
                #cd5b1d calc(50% + 2rem),
                transparent calc(50% + 2rem)
            );
        }
    }

    .game__fun-button {
        background: #637858;
    }

    .game__real-button {
        background: #cd5b1d;
    }

    .game__fun-button:hover {
        background: #637858cc;
    }

    .game__real-button:hover {
        background: #cd5b1dcc;
    }
}

.slots--desktop {
    .games__header-banner {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/desktop-slots-default-banner.png");
    }
}

.casino--desktop {
    .games__header-banner {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/desktop-casino-default-banner.png");
    }
}

.slots--tablet,
.casino--tablet,
.slots--mobile,
.casino--mobile {
    .games-icon--exclusive-wrp {
        @extend %G-games-icon--exclusive-wrp__mobile;
        .games-icon--exclusive-label {
            background: linear-gradient(
                -45deg,
                transparent calc(50% - 0.1rem),
                #cd5b1d 50%,
                #cd5b1d calc(50% + 1.5rem),
                transparent calc(50% + 1rem)
            );
        }
    }
}

.slots--tablet,
.casino--tablet {
    @extend %G-games__tablet;

    .slots-casino-menu__item--active {
        color: #779866;
    }

    .slots-casino-menu__item-icon {
        background-color: #000;
        border: 1px solid #ffffff50;
    }

    .games__search {
        border: 1px solid #ffffff50;

        .ll-input__textbox {
            input {
                color: #fff;
            }
        }
    }

    .games__list {
        min-height: calc(
            100vh - #{$size-height--header-upper-part_desktop + px} - #{$size-height--footer_desktop} - #{$size-height--content-footer}
        );
    }

    .games__item {
        height: $size-height--new-thumbnail-game_tablet + px;
        width: $size-width--new-thumbnail-game_tablet + px;
        margin: $size-margin--new-thumbnail-game_tablet + px;
    }
}

.slots--tablet {
    .games__header-banner {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/desktop-slots-default-banner.png");
    }
}

.casino--tablet {
    .games__header-banner {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/desktop-casino-default-banner.png");
    }
}

.slots--desktop,
.casino--desktop,
.slots--tablet,
.casino--tablet,
.slots--mobile,
.casino--mobile {
    .games__list--empty {
        color: #fff;
    }
}

.slots--mobile,
.casino--mobile {
    @extend %G-games__mobile;

    background-color: #2d2d2d;

    .games-menu--mobile {
        background-color: #2d2d2d;

        .games-menu__btn {
            background-color: #393939;
            color: #ffffff;
        }

        .games-menu__filter-header-item {
            color: #fff;

            &:not(:last-of-type) {
                border-right: 1px solid #ffffff80;
            }
        }

        .games__search {
            color: #bbbbbb;

            input {
                box-shadow: inset 0 0 0 2px #393939;
                color: #bbbbbb;
            }
        }

        .games-menu__btn--active {
            color: #708e61;
        }

        .games-menu__btn--checked {
            color: #708e61;

            &::before {
                background-color: #708e61;
            }

            &::after {
                border-color: #393939;
            }
        }

        .games-menu__submenu-box {
            background-color: #393939;
            color: #ffffff;
        }

        .games-menu__submenu-content {
            background-color: #2d2d2d;
        }

        .games-menu__submenu-header {
            background-color: #393939;
        }

        .games-menu__submenu-item.active {
            color: #708e61;
        }

        .games-menu__submenu-item-img {
            background-color: #000;
            border: 1px solid hsla(0, 0%, 100%, 0.314);
        }
    }

    .games__item {
        height: $size-height--new-thumbnail-game_mobile + px;
        width: $size-width--new-thumbnail-game_mobile + px;
        margin: $size-margin--new-thumbnail-game_mobile + px;
    }

    .games__item--without-image {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/slot-casino-without-img.png");

        .game__game-name {
            background-color: #272727;
        }
    }
}

.slots--mobile {
    .games__header-banner {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/mobile-slots-default-banner.jpg");
    }
}

.casino--mobile {
    .games__header-banner {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/mobile-casino-default-banner.jpg");
    }
}

/* ~~~~~~~~~ JACKPOTS ~~~~~~~~~~ */

.games__list {
    @extend %G-jackpots;

    .jackpot-group__header {
        background-color: #2d2d2d;

        .jackpot-group__header-label {
            color: #ffffff;
        }

        .jackpot-group__header-link {
            color: #fff;
        }
    }

    .jackpot-group__dropdown-wrapper {
        background-color: #393939;
        color: #fff;

        .jackpot-group__dropdown-item--brand {
            background-color: #39393980;
            color: #fff;
        }

        .ll-dropdown-menu-item {
            background-color: #272727;
            color: #bebebe;
        }
    }

    .game-list__group-wrapper {
        .jackpot-group__label--grand {
            color: #ff6d1e;
        }

        .jackpot-group__label--major {
            color: #759d61;
        }

        .jackpot-group__label-value {
            color: #fff;
        }

        .jackpot-group__label-value--grand {
            background-color: #cd5b1d;

            &:hover {
                background-color: #ff6d1e;
            }
        }

        .jackpot-group__label-value--major {
            background-color: #708e61;

            &:hover {
                background-color: #cd5b1d;
            }
        }

        .jackpot-group__label--minor,
        .jackpot-group__label--mini {
            color: #759d61;
        }

        .jackpot-group__small-jackpots {
            .jackpot-group__label-value {
                box-shadow: inset 0 0 1px 1px #759d61;

                &:hover {
                    box-shadow: none;
                    background-color: #759d61;
                }
            }
        }

        .jackpot-group__details--grand,
        .jackpot-group__details--major {
            box-shadow: inset 0 0 1px 1px #ff6d1e;

            .jackpot-group__details-row-info--first {
                color: #ff6d1e;
            }
        }

        .jackpot-group__details--minor,
        .jackpot-group__details--mini {
            box-shadow: inset 0 0 1px 1px #759d61;

            .jackpot-group__details-row-info--first {
                color: #759d61;
            }
        }

        .jackpot-group__details-date {
            color: #bebebe;
        }

        .jackpot-group__details-totals {
            color: #bebebe;
        }
    }
}

.slots--mobile .games__list,
.casino--mobile .games__list {
    @extend %G-jackpots--mobile;
}

.slots--tablet .games__list,
.casino--tablet .games__list,
.slots--desktop .games__list,
.casino--desktop .games__list {
    @extend %G-jackpots--tablet-desktop;
}

.body--with-cursor {
    .games__list {
        .jackpot-group__dropdown-wrapper:hover {
            color: #ff6d1e;
            cursor: pointer;
        }

        .jackpot-group__dropdown-item--link:hover {
            color: #ff6d1e;
        }

        .jackpot-group__header-link:hover {
            color: #ff6d1e;
        }
    }
}
