@import url("//fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin,latin-ext");
.sr-bb {
    font-family: "Roboto", "Noto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    text-align: left;
    background: #2D2D2D;
}

.sr-bb.sr-rtl {
    text-align: right;
}

.sr-bb .sr-bb {
    background: none;
}

.sr-bb .srt-base-1 {
    background-color: transparent;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-win {
    background-color: transparent;
    color: #627757;
    border-color: #627757;
}

.sr-bb .srt-base-1-draw {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    border-color: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-base-1-lose {
    background-color: transparent;
    color: #f7732b;
    border-color: #f7732b;
}

.sr-bb .srt-base-1-is-active {
    background-color: rgba(255, 255, 255, 0.12);
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-2 {
    background-color: #424242;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-hoverable:hover {
    background-color: rgba(255, 255, 255, 0.12);
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-primary {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
}

.sr-bb .srt-base-1-home-1 {
    background-color: transparent;
    color: #627757;
    border-color: #627757;
}

.sr-bb .srt-base-1-away-1 {
    background-color: transparent;
    color: #f7732b;
    border-color: #f7732b;
}

.sr-bb .srt-base-1-home-2 {
    background-color: transparent;
    color: #627757;
    border-color: white;
}

.sr-bb .srt-base-1-away-2 {
    background-color: transparent;
    color: #f7732b;
    border-color: white;
}

.sr-bb .srt-base-1-home-3 {
    background-color: transparent;
    color: #627757;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-away-3 {
    background-color: transparent;
    color: #f7732b;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-home-4 {
    background-color: transparent;
    color: #52614a;
    border-color: #52614a;
}

.sr-bb .srt-base-1-away-4 {
    background-color: transparent;
    color: #c04d0e;
    border-color: #c04d0e;
}

.sr-bb .srt-base-1-home-5 {
    background-color: transparent;
    color: #99a792;
    border-color: #99a792;
}

.sr-bb .srt-base-1-away-5 {
    background-color: transparent;
    color: #ff9459;
    border-color: #ff9459;
}

.sr-bb .srt-base-1-background {
    background-color: #2d2d2d;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2 {
    background-color: #2d2d2d;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2-is-active {
    background-color: #464646;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2-is-hoverable:hover {
    background-color: #464646;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3 {
    background-color: #464646;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-is-active {
    background-color: #515151;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-is-hoverable:hover {
    background-color: #515151;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-background {
    background-color: #2d2d2d;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-4 {
    background-color: #383838;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-5-is-active {
    background-color: #646464;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-5-is-hoverable:hover {
    background-color: #646464;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-6 {
    background-color: transparent;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.3);
}

.sr-bb .srt-primary-1 {
    background-color: #fff;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-active {
    background-color: #e6e6e6;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-hoverable:hover {
    background-color: #e6e6e6;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-disabled {
    background-color: #cccccc;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-2 {
    background-color: white;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-3 {
    background-color: #e6e6e6;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-4 {
    background-color: #ababab;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-5 {
    background-color: #ababab;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-5-is-hoverable:hover {
    background-color: #fff;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-6 {
    background-color: white;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-7 {
    background-color: #fff;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8 {
    background-color: #fff;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8-is-active-1 {
    background-color: #e6e6e6;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8-is-active-2 {
    background-color: #e6e6e6;
    color: #000000;
    border-color: #2D2D2D;
}

.sr-bb .srt-primary-9 {
    background-color: #6c6c6c;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-primary-10 {
    background-color: #d5d5d5;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-11 {
    background-color: #e6e6e6;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-12 {
    background-color: #fff;
    color: #000000;
    border-color: #000000;
}

.sr-bb .srt-primary-13 {
    background-color: #383838;
    color: #000000;
    border-color: rgba(255, 255, 255, 0.3);
}

.sr-bb .srt-base-1-primary-1 {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
}

.sr-bb .srt-base-1-primary-2 {
    background-color: transparent;
    color: white;
    border-color: white;
}

.sr-bb .srt-base-1-primary-3 {
    background-color: transparent;
    color: #e6e6e6;
    border-color: #e6e6e6;
}

.sr-bb .srt-base-1-primary-4 {
    background-color: transparent;
    color: #ababab;
    border-color: #ababab;
}

.sr-bb .srt-base-1-primary-5 {
    background-color: transparent;
    color: #ababab;
    border-color: #ababab;
}

.sr-bb .srt-base-1-primary-6 {
    background-color: transparent;
    color: white;
    border-color: white;
}

.sr-bb .srt-base-1-primary-7 {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
}

.sr-bb .srt-base-1-primary-8 {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
}

.sr-bb .srt-base-1-primary-9 {
    background-color: transparent;
    color: #6c6c6c;
    border-color: #6c6c6c;
}

.sr-bb .srt-base-1-primary-10 {
    background-color: transparent;
    color: #d5d5d5;
    border-color: #d5d5d5;
}

.sr-bb .srt-base-1-primary-11 {
    background-color: transparent;
    color: #e6e6e6;
    border-color: #e6e6e6;
}

.sr-bb .srt-base-1-primary-13 {
    background-color: #2D2D2D;
    color: #383838;
    border-color: rgba(255, 255, 255, 0.3);
}

.sr-bb .srt-base-1-neutral-1 {
    background-color: transparent;
    color: #484848;
    border-color: #484848;
}

.sr-bb .srt-base-1-neutral-2 {
    background-color: transparent;
    color: #eaeaea;
    border-color: #eaeaea;
}

.sr-bb .srt-base-1-neutral-3 {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.12);
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-neutral-4 {
    background-color: transparent;
    color: #b6b6b6;
    border-color: #b6b6b6;
}

.sr-bb .srt-base-1-neutral-5 {
    background-color: transparent;
    color: #cbcbcb;
    border-color: #cbcbcb;
}

.sr-bb .srt-base-1-neutral-6 {
    background-color: transparent;
    color: #5f5f5f;
    border-color: #5f5f5f;
}

.sr-bb .srt-base-1-neutral-7 {
    background-color: transparent;
    color: #b6b6b6;
    border-color: #b6b6b6;
}

.sr-bb .srt-base-1-neutral-8 {
    background-color: transparent;
    color: #484848;
    border-color: #484848;
}

.sr-bb .srt-base-1-neutral-9 {
    background-color: transparent;
    color: #3a3a3a;
    border-color: #3a3a3a;
}

.sr-bb .srt-base-1-neutral-10 {
    background-color: transparent;
    color: #464646;
    border-color: #464646;
}

.sr-bb .srt-base-1-neutral-11 {
    background-color: transparent;
    color: #818181;
    border-color: #818181;
}

.sr-bb .srt-base-1-neutral-12 {
    background-color: transparent;
    color: silver;
    border-color: silver;
}

.sr-bb .srt-base-1-neutral-13 {
    background-color: transparent;
    color: #424242;
    border-color: #424242;
}

.sr-bb .srt-base-1-is-active-primary {
    background-color: rgba(255, 255, 255, 0.12);
    color: #fff;
    border-color: #fff;
}

.sr-bb .srt-base-1-is-active-home-1 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #627757;
    border-color: #627757;
}

.sr-bb .srt-base-1-is-active-away-1 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #f7732b;
    border-color: #f7732b;
}

.sr-bb .srt-base-1-is-active-home-2 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #627757;
    border-color: white;
}

.sr-bb .srt-base-1-is-active-away-2 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #f7732b;
    border-color: white;
}

.sr-bb .srt-base-1-is-active-home-3 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #627757;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-away-3 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #f7732b;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-home-4 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #52614a;
    border-color: #52614a;
}

.sr-bb .srt-base-1-is-active-away-4 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #c04d0e;
    border-color: #c04d0e;
}

.sr-bb .srt-base-1-is-active-home-5 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #99a792;
    border-color: #99a792;
}

.sr-bb .srt-base-1-is-active-away-5 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #ff9459;
    border-color: #ff9459;
}

.sr-bb .srt-base-1-is-active-primary-1 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #fff;
    border-color: #fff;
}

.sr-bb .srt-base-1-is-active-primary-2 {
    background-color: rgba(255, 255, 255, 0.12);
    color: white;
    border-color: white;
}

.sr-bb .srt-base-1-is-active-primary-3 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #e6e6e6;
    border-color: #e6e6e6;
}

.sr-bb .srt-base-1-is-active-primary-4 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #ababab;
    border-color: #ababab;
}

.sr-bb .srt-base-1-is-active-primary-5 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #ababab;
    border-color: #ababab;
}

.sr-bb .srt-base-1-is-active-primary-6 {
    background-color: rgba(255, 255, 255, 0.12);
    color: white;
    border-color: white;
}

.sr-bb .srt-base-1-is-active-primary-7 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #fff;
    border-color: #fff;
}

.sr-bb .srt-base-1-is-active-primary-8 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #fff;
    border-color: #fff;
}

.sr-bb .srt-base-1-is-active-primary-9 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #6c6c6c;
    border-color: #6c6c6c;
}

.sr-bb .srt-base-1-is-active-primary-10 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #d5d5d5;
    border-color: #d5d5d5;
}

.sr-bb .srt-base-1-is-active-primary-11 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #e6e6e6;
    border-color: #e6e6e6;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #484848;
    border-color: #484848;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #eaeaea;
    border-color: #eaeaea;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
    background-color: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.12);
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #b6b6b6;
    border-color: #b6b6b6;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #cbcbcb;
    border-color: #cbcbcb;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #5f5f5f;
    border-color: #5f5f5f;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #b6b6b6;
    border-color: #b6b6b6;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #484848;
    border-color: #484848;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #3a3a3a;
    border-color: #3a3a3a;
}

.sr-bb .srt-base-1-is-active-neutral-10 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #464646;
    border-color: #464646;
}

.sr-bb .srt-base-1-is-active-neutral-11 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #818181;
    border-color: #818181;
}

.sr-bb .srt-base-1-is-active-neutral-12 {
    background-color: rgba(255, 255, 255, 0.12);
    color: silver;
    border-color: silver;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #424242;
    border-color: #424242;
}

.sr-bb .srt-home-1 {
    background-color: #627757;
    color: #ffffff;
    border-color: #627757;
}

.sr-bb .srt-away-1 {
    background-color: #f7732b;
    color: #000000;
    border-color: #f7732b;
}

.sr-bb .srt-home-2 {
    background-color: #627757;
    color: #ffffff;
    border-color: white;
}

.sr-bb .srt-away-2 {
    background-color: #f7732b;
    color: #000000;
    border-color: white;
}

.sr-bb .srt-home-3 {
    background-color: #627757;
    color: #ffffff;
    border-color: #2D2D2D;
}

.sr-bb .srt-away-3 {
    background-color: #f7732b;
    color: #000000;
    border-color: #2D2D2D;
}

.sr-bb .srt-home-4 {
    background-color: #52614a;
    color: #ffffff;
    border-color: #52614a;
}

.sr-bb .srt-away-4 {
    background-color: #c04d0e;
    color: #000000;
    border-color: #c04d0e;
}

.sr-bb .srt-home-5 {
    background-color: #99a792;
    color: #ffffff;
    border-color: #99a792;
}

.sr-bb .srt-away-5 {
    background-color: #ff9459;
    color: #000000;
    border-color: #ff9459;
}

.sr-bb .srt-neutral-1 {
    background-color: #484848;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-2 {
    background-color: #eaeaea;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-3 {
    background-color: rgba(255, 255, 255, 0.12);
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-4 {
    background-color: #b6b6b6;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-5 {
    background-color: #cbcbcb;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-6 {
    background-color: #5f5f5f;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-7 {
    background-color: #b6b6b6;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-8 {
    background-color: #484848;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-9 {
    background-color: #3a3a3a;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-10 {
    background-color: #464646;
    color: rgba(255, 255, 255, 0.82);
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-11 {
    background-color: #818181;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-12 {
    background-color: silver;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-13 {
    background-color: #424242;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-win {
    background-color: #627757;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-draw {
    background-color: rgba(255, 255, 255, 0.4);
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-lose {
    background-color: #f7732b;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-text-secondary {
    opacity: 0.8;
    -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-text-disabled {
    opacity: 0.6;
}

.sr-bb .srt-text-tertiary {
    opacity: 0.7;
}

.sr-bb .srt-primary-1 .srt-text-secondary, .sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
    opacity: 0.7;
    -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-primary-1 .srt-text-disabled, .sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
    opacity: 0.35;
}

.sr-bb .srt-primary-1 .srt-text-tertiary, .sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
    opacity: 0.54;
}

.sr-bb .srt-icon {
    opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
    opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.sr-bb .srt-elevation-2 {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23), 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-elevation-3 {
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.26), 0px 10px 20px 0px rgba(0, 0, 0, 0.19);
}

.sr-bb .srt-elevation-center-2 {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-inset-top-1 {
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-1 {
    box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-2 {
    box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-2 {
    box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-3 {
    box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-3 {
    box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-fill-blue-card {
    fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
    stroke: #2579ad;
}

.sr-bb .srt-fill-soccer-yellow-card {
    fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
    stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
    fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
    stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
    stroke: #4fbe30;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-in {
    fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
    stroke: #e43b3b;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-out {
    fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
    stroke: #e43b3b;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-own-goal {
    fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
    fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
    stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
    fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
    stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
    fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
    stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
    fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
    stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
    fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
    stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
    fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
    stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
    fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
    stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
    fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
    stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
    fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
    stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
    fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
    stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
    background-color: #f5a623;
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-nfl-timeout-2 {
    background-color: rgba(245, 166, 35, 0.3);
    color: #000000;
    border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-nfl-penalty {
    background-color: #f4a621;
    color: #000000;
    border-color: #f4a621;
}

.sr-bb .srt-nfl-negative-yards-1 {
    background-color: #ac182e;
    color: #000000;
    border-color: #ac182e;
}

.sr-bb .srt-nfl-negative-yards-2 {
    background-color: #ac182e;
    color: #000000;
    border-color: #ac182e;
}

.sr-bb .srt-nfl-first-line {
    background-color: #4b90de;
    color: #000000;
    border-color: #4b90de;
}

.sr-bb .srt-nfl-ten-line {
    background-color: #f5a623;
    color: #000000;
    border-color: #f5a623;
}

.sr-bb .srt-fill-nfl-penalty {
    fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
    stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
    fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
    stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
    fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
    stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
    fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
    stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
    fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
    stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
    background-color: #009900;
    color: #ffffff;
    border-color: #009900;
}

.sr-bb .srt-mlb-run-2 {
    background-color: #ffffff;
    color: #009900;
    border-color: #009900;
}

.sr-bb .srt-mlb-hit-1 {
    background-color: #f7ab11;
    color: #fff;
    border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-2 {
    background-color: #ffffff;
    color: #f7ab11;
    border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
    background-color: #00a4ff;
    color: #ffffff;
    border-color: #00a4ff;
}

.sr-bb .srt-mlb-error-1 {
    background-color: #d0021b;
    color: #ffffff;
    border-color: #d0021b;
}

.sr-bb .srt-mlb-error-2 {
    background-color: #ffffff;
    color: #d0021b;
    border-color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
    background-color: #212121;
    color: #ffffff;
    border-color: #ffffff;
}

.sr-bb .srt-fill-mlb-base {
    fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
    fill: #00a4ff;
}

.sr-bb .srt-fill-change-increase {
    fill: #627757;
}

.sr-bb .srt-stroke-change-increase {
    stroke: #627757;
}

.sr-bb .srt-fill-change-decrease {
    fill: #f7732b;
}

.sr-bb .srt-stroke-change-decrease {
    stroke: #f7732b;
}

.sr-bb .srt-fill-text {
    fill: #fff;
}

.sr-bb .srt-fill-text-invert {
    fill: #000000;
}

.sr-bb .srt-fill-text-secondary {
    fill-opacity: 0.8;
}

.sr-bb .srt-fill-text-disabled {
    fill-opacity: 0.6;
}

.sr-bb .srt-fill-base-1 {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
    fill: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-stroke-base-1-active {
    stroke: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-fill-base-1-active-2 {
    fill: #424242;
}

.sr-bb .srt-stroke-base-1-active-2 {
    stroke: #424242;
}

.sr-bb .srt-fill-base-1-primary {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
    stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
    fill: currentColor;
}

.sr-bb .srt-stroke-base-2 {
    stroke: currentColor;
}

.sr-bb .srt-fill-base-2-active {
    fill: #464646;
}

.sr-bb .srt-stroke-base-2-active {
    stroke: #464646;
}

.sr-bb .srt-fill-base-2-hover {
    fill: #464646;
}

.sr-bb .srt-stroke-base-2-hover {
    stroke: #464646;
}

.sr-bb .srt-fill-base-3 {
    fill: #464646;
}

.sr-bb .srt-stroke-base-3 {
    stroke: #464646;
}

.sr-bb .srt-fill-base-3-active {
    fill: #515151;
}

.sr-bb .srt-stroke-base-3-active {
    stroke: #515151;
}

.sr-bb .srt-fill-base-3-hover {
    fill: #515151;
}

.sr-bb .srt-stroke-base-3-hover {
    stroke: #515151;
}

.sr-bb .srt-fill-primary-1 {
    fill: #fff;
}

.sr-bb .srt-stroke-primary-1 {
    stroke: #fff;
}

.sr-bb .srt-fill-primary-2 {
    fill: white;
}

.sr-bb .srt-stroke-primary-2 {
    stroke: white;
}

.sr-bb .srt-fill-primary-3 {
    fill: #e6e6e6;
}

.sr-bb .srt-stroke-primary-3 {
    stroke: #e6e6e6;
}

.sr-bb .srt-fill-primary-4 {
    fill: #ababab;
}

.sr-bb .srt-stroke-primary-4 {
    stroke: #ababab;
}

.sr-bb .srt-fill-primary-5 {
    fill: #ababab;
}

.sr-bb .srt-stroke-primary-5 {
    stroke: #ababab;
}

.sr-bb .srt-fill-primary-6 {
    fill: white;
}

.sr-bb .srt-stroke-primary-6 {
    stroke: white;
}

.sr-bb .srt-fill-primary-7 {
    fill: #fff;
}

.sr-bb .srt-stroke-primary-7 {
    stroke: #fff;
}

.sr-bb .srt-fill-primary-8 {
    fill: #fff;
}

.sr-bb .srt-stroke-primary-8 {
    stroke: #fff;
}

.sr-bb .srt-fill-primary-8-is-active-1 {
    fill: #e6e6e6;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
    stroke: #e6e6e6;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
    fill: #e6e6e6;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
    stroke: #e6e6e6;
}

.sr-bb .srt-fill-primary-9 {
    fill: #6c6c6c;
}

.sr-bb .srt-stroke-primary-9 {
    stroke: #6c6c6c;
}

.sr-bb .srt-fill-primary-10 {
    fill: #d5d5d5;
}

.sr-bb .srt-stroke-primary-10 {
    stroke: #d5d5d5;
}

.sr-bb .srt-fill-primary-11 {
    fill: #e6e6e6;
}

.sr-bb .srt-stroke-primary-11 {
    stroke: #e6e6e6;
}

.sr-bb .srt-fill-primary-12 {
    fill: #fff;
}

.sr-bb .srt-stroke-primary-12 {
    stroke: #fff;
}

.sr-bb .srt-fill-home-1 {
    fill: #627757;
}

.sr-bb .srt-stroke-home-1 {
    stroke: #627757;
}

.sr-bb .srt-fill-home-2 {
    fill: #627757;
}

.sr-bb .srt-stroke-home-2 {
    stroke: #627757;
}

.sr-bb .srt-fill-home-3 {
    fill: #627757;
}

.sr-bb .srt-stroke-home-3 {
    stroke: #627757;
}

.sr-bb .srt-fill-home-4 {
    fill: #52614a;
}

.sr-bb .srt-stroke-home-4 {
    stroke: #52614a;
}

.sr-bb .srt-fill-home-5 {
    fill: #99a792;
}

.sr-bb .srt-stroke-home-5 {
    stroke: #99a792;
}

.sr-bb .srt-fill-away-1 {
    fill: #f7732b;
}

.sr-bb .srt-stroke-away-1 {
    stroke: #f7732b;
}

.sr-bb .srt-fill-away-2 {
    fill: #f7732b;
}

.sr-bb .srt-stroke-away-2 {
    stroke: #f7732b;
}

.sr-bb .srt-fill-away-3 {
    fill: #f7732b;
}

.sr-bb .srt-stroke-away-3 {
    stroke: #f7732b;
}

.sr-bb .srt-fill-away-4 {
    fill: #c04d0e;
}

.sr-bb .srt-stroke-away-4 {
    stroke: #c04d0e;
}

.sr-bb .srt-fill-away-5 {
    fill: #ff9459;
}

.sr-bb .srt-stroke-away-5 {
    stroke: #ff9459;
}

.sr-bb .srt-fill-neutral-1 {
    fill: #484848;
}

.sr-bb .srt-stroke-neutral-1 {
    stroke: #484848;
}

.sr-bb .srt-fill-neutral-2 {
    fill: #eaeaea;
}

.sr-bb .srt-stroke-neutral-2 {
    stroke: #eaeaea;
}

.sr-bb .srt-fill-neutral-3 {
    fill: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-stroke-neutral-3 {
    stroke: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-fill-neutral-4 {
    fill: #b6b6b6;
}

.sr-bb .srt-stroke-neutral-4 {
    stroke: #b6b6b6;
}

.sr-bb .srt-fill-neutral-5 {
    fill: #cbcbcb;
}

.sr-bb .srt-stroke-neutral-5 {
    stroke: #cbcbcb;
}

.sr-bb .srt-fill-neutral-6 {
    fill: #5f5f5f;
}

.sr-bb .srt-stroke-neutral-6 {
    stroke: #5f5f5f;
}

.sr-bb .srt-fill-neutral-7 {
    fill: #b6b6b6;
}

.sr-bb .srt-stroke-neutral-7 {
    stroke: #b6b6b6;
}

.sr-bb .srt-fill-neutral-8 {
    fill: #484848;
}

.sr-bb .srt-stroke-neutral-8 {
    stroke: #484848;
}

.sr-bb .srt-fill-neutral-9 {
    fill: #3a3a3a;
}

.sr-bb .srt-stroke-neutral-9 {
    stroke: #3a3a3a;
}

.sr-bb .srt-fill-neutral-10 {
    fill: #464646;
}

.sr-bb .srt-stroke-neutral-10 {
    stroke: #464646;
}

.sr-bb .srt-fill-neutral-11 {
    fill: #818181;
}

.sr-bb .srt-stroke-neutral-11 {
    stroke: #818181;
}

.sr-bb .srt-fill-neutral-12 {
    fill: silver;
}

.sr-bb .srt-stroke-neutral-12 {
    stroke: silver;
}

.sr-bb .srt-fill-neutral-13 {
    fill: #424242;
}

.sr-bb .srt-stroke-neutral-13 {
    stroke: #424242;
}

.sr-bb .srt-fill-win {
    fill: #627757;
}

.sr-bb .srt-stroke-win {
    stroke: #627757;
}

.sr-bb .srt-fill-draw {
    fill: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-stroke-draw {
    stroke: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-fill-lose {
    fill: #f7732b;
}

.sr-bb .srt-stroke-lose {
    stroke: #f7732b;
}

.sr-bb .srt-stop-base-1 {
    stop-color: transparent;
}

.sr-bb .srt-stop-primary-1 {
    stop-color: #fff;
}

.sr-bb .srt-stop-primary-2 {
    stop-color: white;
}

.sr-bb .srt-stop-primary-3 {
    stop-color: #e6e6e6;
}

.sr-bb .srt-stop-primary-4 {
    stop-color: #ababab;
}

.sr-bb .srt-stop-primary-5 {
    stop-color: #ababab;
}

.sr-bb .srt-stop-primary-6 {
    stop-color: white;
}

.sr-bb .srt-stop-primary-7 {
    stop-color: #fff;
}

.sr-bb .srt-stop-primary-8 {
    stop-color: #fff;
}

.sr-bb .srt-stop-primary-9 {
    stop-color: #6c6c6c;
}

.sr-bb .srt-stop-primary-10 {
    stop-color: #d5d5d5;
}

.sr-bb .srt-stop-primary-11 {
    stop-color: #e6e6e6;
}

.sr-bb .srt-stop-primary-12 {
    stop-color: #fff;
}

.sr-bb .srt-stop-home-1 {
    stop-color: #627757;
}

.sr-bb .srt-stop-away-1 {
    stop-color: #f7732b;
}

.sr-bb .srt-fill-neutral-14 {
    fill: #2D2D2D;
}

.sr-bb .srt-stroke-neutral-14 {
    stroke: #2D2D2D;
}

.sr-bb .srt-logo-powered-by-light {
    display: none;
}

.sr-bb .srt-logo-powered-by-dark {
    display: inline-block;
}

.sr-poweredby__logo {
    display: none!important;
}

.sr-bs-scoreboard__corners,
.sr-hth-team-form-table__last-x-arrow,
.sr-mp-5-matchinfo__segment {
    svg path {
        fill: #fff;
    }
}

.sr-scoreboard-header-horizontal__corners-icon {
    filter: invert(1);
}