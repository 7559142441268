/*
3.PAGES
├── 3.1. PREMATCH COMMON ELEMENTS
│   └── 3.1.2. PREMATCH BREADCRUMBS DROPDOWN
│   ├── 3.1.3. PREMATCH RANGED EVENTS PICKER
│   ├── 3.1.4. PREMATCH LL-WIDGETS
│   ├── 3.1.5. SPORT MARKET
│   └── 3.1.6. COMMON MATCH LIST ITEM
├── 3.2. PREMATCH PAGE(desktop)
│   ├── 3.2.1 PREMATCH LEFT MENU
│   ├── 3.1.2 PREMATCH CENTER
│   │   ├── 3.1.2.1 PREMATCH - MATCHLIST
│   │   └── 3.1.2.2 PREMATCH CENTER
│   └── 3.1.3 PREMATCH RIGHT CONTENT (COUPONS)
└── 3.1.m PREMATCH PAGE(mobile)
    ├── 3.1.m.1 PREMATCH MOBILE MENU
    ├── 3.1.m.2 PREMATCH TOURNAMENT MOBILE MENU
    ├── 3.1.m.3 MATCH DETAILS
    └── 3.1.m.4 PREMATCH COUPONS
*/

/*
|--------------------------------------------------------------------------
| == 3.PAGES
|--------------------------------------------------------------------------
| == 3.1. PREMATCH COMMON ELEMENTS
|--------------------------------------------------------------------------
 */

.ll-page.prematch--desktop {
    min-height: $size-max-height--page-container_desktop;
    max-height: $size-max-height--page-container_desktop;
}

.ll-page.prematch--tablet {
    padding: .5rem 0;
    min-height: $size-min-height--page-container_tablet;
    max-height: $size-max-height--page-container_tablet;

    //ios crutch
    @supports (-webkit-touch-callout: none) {
        min-height: calc(#{$size-min-height--page-container_tablet} - 7rem);
        max-height: calc(#{$size-max-height--page-container_tablet} - 7rem);
    }

    &.sports-live {
        max-height: fit-content;
    }

    .live-page__landscape-scrollbox {
        max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
        - #{$size-height--header-nav-menu_tablet} - 4.5rem);

        @supports (-webkit-touch-callout: none) {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - 11.5rem);
        }
    }
}

.ll-page.prematch--tablet.prematch--tablet__with-ticket {
    min-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
    - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one});
    max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
    - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one});

    @supports (-webkit-touch-callout: none) {
        min-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
        - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - 7rem);
        max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
        - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - 7rem);
    }

    .live-detail__section,
    .live-menu__section {
        padding-bottom: $size-height__prematch-coupons--one;
    }

    &.sports-live {
        max-height: fit-content;
    }

    @media screen and (orientation: landscape) {
        max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
        - #{$size-height--header-nav-menu_tablet});

        @supports (-webkit-touch-callout: none) {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - 7rem);
        }

        @media screen and (max-width: 1023px) {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one});

            @supports (-webkit-touch-callout: none) {
                max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
                - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - 7rem);
            }
        }

        &.sports-live {
            max-height: fit-content;
        }

        .live-page__landscape-scrollbox {
            min-height: auto;
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - 4.5rem);

            @supports (-webkit-touch-callout: none) {
                max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
                - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - 11.5rem);
            }
        }

        .betting-dashboard {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - .5rem);

            @supports (-webkit-touch-callout: none) {
                max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
                - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--one} - 7.5rem);
            }
        }

        .betting-dashboard .ll-scrollbox--tablet {
            min-height: auto;
            max-height: fit-content;
        }
    }
}

.ll-page.prematch--tablet.prematch--with-tickets {
    padding-bottom: $size-height__prematch-coupons--few;

    @media screen and (orientation: landscape) {
        max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
        - #{$size-height--header-nav-menu_tablet});
        padding-bottom: 0;

        @supports (-webkit-touch-callout: none) {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - 7.5rem);
        }

        @media screen and (max-width: 1023px) {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet});
            padding-bottom: $size-height__prematch-coupons--few;

            @supports (-webkit-touch-callout: none) {
                max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
                - #{$size-height--header-nav-menu_tablet} - 7rem);
            }
        }

        &.sports-live {
            max-height: fit-content;
        }

        .live-menu__section {
            padding-bottom: $size-height__prematch-coupons--few;
        }

        .live-page__landscape-scrollbox {
            min-height: auto;
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--few} - 4.5rem);

            @supports (-webkit-touch-callout: none) {
                max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
                - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--few} - 11.5rem);
            }
        }
        .betting-dashboard {
            max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
            - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--few} - .5rem);

            @supports (-webkit-touch-callout: none) {
                max-height: calc(100vh - #{$size-height--footer_tablet} - #{$size-height--header_tablet}
                - #{$size-height--header-nav-menu_tablet} - #{$size-height__prematch-coupons--few} - 7.5rem);
            }
        }
    }
}

/* ---------------------- Slider ---------------------- */

.pre-market-groups__slider {
    @extend %G-pre-market-groups-slider;

    background-color: #637858;

    .market-groups__slider-arrow {
        color: #fff;
    }

    .pre-match-details__market-group,
    .pre-match-details__market-group--active {
        color: #fff;
    }

    .pre-match-details__market-group--active {
        background-color: #CD5B1D;
        color: #fff;
    }

    .slick-prev,
    .slick-next {
        z-index: 1;
    }

    .slick-prev {
        left: .7rem;

        &:before {
            font-size: 1.5rem;
        }
    }

    .slick-next {
        right: .7rem;

        &:before {
            font-size: 1.5rem;
        }
    }
}

.body--with-cursor {
    .pre-market-groups__slider {
        .pre-match-details__market-group:hover {
            background-color: #CD5B1D;
            color: #fff;
        }
    }
}

.market-groups__slider--disabled:before {
    background: #ffffff20;
}

/*
|--------------------------------------------------------------------------
| == 3.1.2. PREMATCH BREADCRUMBS DROPDOWN
|--------------------------------------------------------------------------
*/

.prematch-breadcrumbs-dropdown {
    @extend %G-prematch-breadcrumbs-dropdown;

    .prematch-breadcrumbs-dropdown__content {
        color: #393939;
    }
}

.body--with-cursor {
    .prematch-breadcrumbs-dropdown__match-item--match:hover {
        .prematch-breadcrumbs-dropdown__match-name {
            color: #cd5b1d;
        }
    }
}

.prematch-breadcrumbs-dropdown--desktop,
.prematch-breadcrumbs-dropdown--tablet {
    @extend %G-prematch-breadcrumbs-dropdown--tablet--desktop;

    .prematch-breadcrumbs-dropdown__content {
        border: 1px solid #515151;
        background: #393939;
        color: #fff;
    }

    .prematch-breadcrumbs-dropdown__match-item {
        &:not(:last-of-type) {
            border-bottom: 1px solid #515151;
        }
    }

    .prematch-breadcrumbs-dropdown__match-item--date {
        background-color: #405337;
        color: #fff;
    }

    .prematch-breadcrumbs-dropdown__match-item--breadcrumbs {
        ///background-color: #dadad9;
    }
}

.prematch-breadcrumbs-dropdown--mobile {
    @extend %G-prematch-breadcrumbs-dropdown--mobile;

    .prematch-breadcrumbs-dropdown__content {
        background-color: #393939;
        color: #fff;
    }

    .prematch-breadcrumbs-dropdown__match-item--match,
    .prematch-breadcrumbs-dropdown__match-item--tournament {
        &:not(:last-of-type) {
            border-bottom: 1px solid #2D2D2D;
        }
    }

    .prematch-breadcrumbs-dropdown__match-item--date,
    .prematch-breadcrumbs-dropdown__match-item--breadcrumbs {
        background-color: #2D2D2D;
    }

    .prematch-breadcrumbs-dropdown__match-item--date {
        color: #DDDDDD;
    }

    .prematch-breadcrumbs-dropdown__match-item--breadcrumbs {
        color: #fff;
        border-bottom: 1px solid #393939;
    }

    .prematch-breadcrumbs-dropdown__match-count {
        background-color: #708E61;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.3. PREMATCH RANGED EVENTS PICKER
|--------------------------------------------------------------------------
*/

.ranged-events-picker {
    @extend %G-ranged-events-picker;

    color: #FFFFFF;
}

.ranged-events-picker--desktop,
.ranged-events-picker--tablet {
    @extend %G-ranged-events-picker--tablet--desktop;

    border-top: 1px solid #637858;
    background: #2D3628;

    .slider__item {
        &:not(:last-child) {
            border-right: 1px solid #637858;
        }
    }

    .ranged-events-picker__slider-item--active {
        background: #CD5B1D;
    }
}

.ranged-events-picker--mobile {
    @extend %G-ranged-events-picker--mobile;

    background-color: #272727;

    .ranged-events-picker__slider-item {
        background-color: #393939;
    }

    .ranged-events-picker__slider-item--active {
        color: #CD5B1D;
    }
}

.body--with-cursor .ranged-events-picker {
    .ranged-events-picker__slider-item {
        cursor: pointer;

        &:hover {
            background: #CD5B1D;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.4. PREMATCH LL-WIDGETS
|--------------------------------------------------------------------------
*/

.ll-sport-widget {
    @extend %G-ll-sport-widget;

    background-color: #272727;

    .ll-sport-widget__slider-arrow {
        color: #fff;
    }
}

.page-wrapper--desktop .ll-sport-widget {
    @extend %G-ll-sport-widget--desktop;

    background-color: #2D2D2D;
}

/*
|--------------------------------------------------------------------------
| == 3.1.6. COMMON MATCH LIST ITEM
|--------------------------------------------------------------------------
*/

.common-match-item {
    @extend %G-common-match-item;
}

.common-match-item--mobile {
    border-bottom: 1px solid #2d2d2d;
    background: #222222;
    color: #fff;

    .matches__date {
        color: #DDDDDD;
    }

    .matches__bets-btn,
    .matches__bets-btn--active {
        color: #fff;
    }

    .matches__bets-btn {
        background: #393939;
        color: #FF6D1E;

        .matches__bets-btn-value-original-odd {
            color: #ffffff80;
        }
    }

    .matches__odds-count-btn {
        color: #BEBEBE;
    }

    .matches__bets-btn--active {
        background: #cd5b1d;
        color: #fff;

        .matches__bets-btn-value-original-odd {
            color: #ffffff80;

            &::after {
                color: #fff;
            }
        }
    }
}

.common-match-item--mobile,
.common-match-item--tablet {
    @extend %G-common-match-item--mobile--tablet;
}

.common-match-item--tablet,
.common-match-item--desktop {
    border-bottom: 1px solid #393939;
    background: #2d2d2d;
    color: #fff;

    .matches__date {
        color: #bebebe;
    }

    .matches__bets-btn {
        background: #637858;
        color: #fff;

        .matches__bets-btn-value-original-odd {
            color: #ffffff80;

            &::after {
                color: #fff;
            }
        }
    }

    .matches__odds-count-btn {
        color: #DDDDDD;
    }

    .matches__bets-btn--active {
        background: #cd5b1d;
        color: #fff;

        .matches__bets-btn-value-original-odd {
            color: #ffffff80;

            &::after {
                color: #fff;
            }
        }
    }
}

.common-match-item--desktop {
    @extend %G-common-match-item--desktop;
}

.body--with-cursor {
    .matches__bets-btn:hover {
        background: #cd5b1d;
        color: #fff;

        .matches__bets-btn-value-original-odd {
            color: #ffffff80;

            &::after {
                color: #fff;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2. PREMATCH PAGE(desktop)
|--------------------------------------------------------------------------
*/
.prematch--tablet,
.prematch--desktop {
    @extend %G-prematch--tablet--desktop;

    .prematch__loading {
        background: rgba(255, 255, 255, 0.4);
    }

    .prematch__loading--initial {
        background: rgba(0, 0, 0, 0.2);
    }

    .prematch__breadcrumbs {
        box-shadow: inset 0 1px 0 0 hsla(0, 0%, 100%, 0.2),
        0 1px 0 0 hsla(0, 0%, 100%, 0.1);
        background: #637858;
        color: #fff;
    }

    .popular-tournaments {
        .popular-tournaments__list-item,
        .popular-tournaments__header {
            background: #405337;
            color: #fff;
        }

        .popular-tournaments__list-item {
            background: #515151;
            color: #fff;
        }

        .popular-tournaments__match-count {
            background: #637858;
            color: #fff;
        }

        .active {
            background-color: #637858;
            color: #fff;

            .popular-tournaments__match-count {
                background: #fff;
                color: #637858;
            }
        }
    }
}

.body--with-cursor {
    .popular-tournaments__list-item:hover {
        background-color: #637858;
        color: #fff;

        .popular-tournaments__match-count {
            background: #fff;
            color: #637858;
        }
    }

    .prematch__breadcrumbs-text-link:hover {
        color: #cd5b1d;
    }
}

.prematch--tablet {
    @extend %G-prematch--tablet;
}

/*
 |--------------------------------------------------------------------------
 | == 3.2.1 PREMATCH LEFT MENU
 |--------------------------------------------------------------------------
*/

.prematch--tablet {
    .prematch__menu-section {
        min-width: $size-width__prematch-menu--tablet-768;
        max-width: $size-width__prematch-menu--tablet-768;

        @include mq('(min-width: 1024px)') {
            min-width: $size-width__prematch-menu--tablet-1024;
            max-width: $size-width__prematch-menu--tablet-1024;
        }
    }
}

.prematch--desktop {
    .prematch__menu-section {
        min-width: $size-width__prematch-menu--desktop-1366;
        max-width: $size-width__prematch-menu--desktop-1366;
    }
}

/* ---------------------- Left Menu Component ---------------------- */

.prematch__menu {
    @extend %G-prematch__menu;

    &.prematch__menu-disabled:before {
        background: #ffffff50;
    }

    .prematch__menu-filters-btn {
        border: 1px solid #393939;
        background-color: #2D2D2D;
        color: #DDDDDD;
    }

    .menu__loading {
        // TODO: Calc these props properly for tablet/desktop
        min-height: calc(100vh - 350px);
        max-height: calc(100vh - 350px);

        background: rgba(0, 0, 0, 0.2);
    }

    .menu-item {
        border: 1px solid #2D2D2D;
        background-color: #2D2D2D;
        color: #fff;
    }

    .menu-item--category,
    .menu-item--tournament {
        background-color: #393939;
    }

    .menu-item--active {
        background: #637858;
        color: #fff;
    }

    .menu-item__progress-bar .ll-progress-bar__value {
        background-color: #ff5a00;
    }

    .prematch__menu-extra-btn {
        background: #688b55;
    }
}

.prematch--tablet {
    .prematch__menu {
        @extend %G-prematch__menu--tablet;
    }
}

.prematch--desktop {
    .prematch__menu {
        @extend %G-prematch__menu--desktop;
    }
}

.body--with-cursor {
    .prematch__menu {
        @extend %G-prematch__menu--with-cursor;

        .prematch__menu-filters-btn:hover {
            color: #fff;
        }

        .menu-item:hover {
            background: #637858;
            color: #fff;
        }

        .prematch__menu-extra-btn:hover {
            background: #ff5a00 !important;

            &::before {
                background-color: #fff;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.2 PREMATCH CENTER
|--------------------------------------------------------------------------
*/

.prematch--tablet,
.prematch--desktop {
    .betting-dashboard {
        @extend %G-betting-dashboard--tablet--desktop;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.1 PREMATCH - MATCHLIST
|--------------------------------------------------------------------------
*/

.prematch--tablet,
.prematch--desktop {
    .match-list {
        @extend %G-match-list--tablet--desktop;

        .match-list__date {
            background-color: #393939;
            color: #fff;
        }

        .match {
            border-left: 1px solid #393939;
            background-color: #2D2D2D;
            color: #fff;
        }

        .match__time,
        .match__time--full,
        .match__bet,
        .match__odds-count,
        .match__additional-info-box,
        .ll-outright-event .match__bets {
            border-right: 1px solid #393939;
            border-bottom: 1px solid #393939;
        }

        .match__tv--disabled {
            opacity: .8;
        }

        .match__bet {
            &.match__bet--active {
                background: #637858;
                color: #fff;

                .match__bet-value {
                    color: #fff;
                }

                .match__bet-value-original-odd {
                    color: #fff;
                }
            }
        }

        .match__icon--can-cashout,
        .match__icon--cant-cashout {
            color: #fff;
        }

        .match__icon--cant-cashout {
            opacity: .5;
        }

        .match__bet-value {
            color: #d06a39;
        }

        .match__bet-value-original-odd {
            color: #515151;
        }

        .match__tv {
            color: #56913c;
        }

        /* ---------------------- Outrights ---------------------- */
        .ll-outright-tournament {
            .ll-outright-tournament__accordion,
            .ll-outright-tournament__accordion--active {
                border: 1px solid #515151;
                border-top: none;
                background: #393939;
                color: #fff;
            }
        }

        .match.ll-outright-event {
            .match__odds-count {
                width: $size-width__prematch-list-odds-count--tablet;
            }
        }

        /* ---------------------- Tops/Favs ---------------------- */
        .match-list__top-fav-title {
            color: #fff;

            &.match-list__top-fav-title--top {
                background: #405337;
            }

            &.match-list__top-fav-title--favorite {
                background: #2D3628;
            }
        }

        /* ---------------------- Search ---------------------- */
        .match-list__search-title {
            background: #688b55;
            color: #fff;
        }

        .match-list__search-no-data-text {
            border: 1px solid #393939;
            background-color: #2D2D2D;
            color: #fff;
        }
    }

    .match.ll-outright-event {
        .match__icon--can-cashout,
        .match__icon--cant-cashout {
            color: #fff;
        }
        .match__icon--cant-cashout {
            opacity: .8;
        }
    }
}

.prematch--tablet {
    .match-list {
        @extend %G-match-list--tablet;
    }
}

.body--with-cursor {
    .prematch--tablet,
    .prematch--desktop {
        .match-list {
            .match__bet:not(.match__bet--disabled):not(.match__bet--without-main-market):hover {
                background: #637858;
                color: #fff;

                .match__bet-value {
                    color: #fff;
                }

                .match__bet-value-original-odd {
                    color: #fff;
                }
            }

            .match__tv,
            .match__tv--disabled,
            .match__icon-betbuilder,
            .match__stats {
                &:hover {
                    color: #ff5a00;
                }
            }

            .match:not(.ll-outright-event) .match__odds-count:hover {
                color: #fff;
                background: #cd5b1d;
            }

            .match.ll-outright-event:hover {
                .match__bets {
                    background: #637858;
                    color: #fff;
                }
                .match__icon--can-cashout,
                .match__icon--cant-cashout {
                    color: #fff;
                }
            }
        }
    }
}

.filtered-matches-list--mobile {
    @extend %G-filtered-matches-list;

    .filtered-matches-list__breadcrumbs-wrp {
        background: #2D2D2D;
        color: #DDDDDD;
    }
}

.filtered-matches-list--tablet,
.filtered-matches-list--desktop {
    @extend %G-filtered-matches-list;

    .filtered-matches-list__breadcrumbs-wrp {
        background: #393939;
        color: #bebebe;
    }

    .filtered-matches-list__no-data-text {
        color: #fff;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.1 PREMATCH - DETAILS
|--------------------------------------------------------------------------
*/

.match-details--desktop,
.match-details--tablet,
.match-details--mobile,
.live-match-details {
    .fancy-bets-slider-name {
        color: #DDDDDD;
    }

    .fancy-bets-slider {
        color: #637858;
    }

    .fancy-bets-slider__rail {
        color: #7C7C7C;
    }

    .fancy-bets-slider__label {
        color: #fff;
    }
}

.match-details--tablet,
.match-details--desktop {
    @extend %G-match-details--tablet--desktop;

    .match-details__error {
        background: #fff;
    }

    .match-details__empty-markets {
        background-color: #fff;
        color: #000;
    }

    .match-details__market-header {
        background-color: #393939;
        color: #fff;
    }

    .status-filter-switcher .status-filter-switcher-track {
        background-color: #7C7C7C !important;
    }

    .market__icon--can-cashout,
    .market__icon--cant-cashout {
        color: #fff;
    }

    .market__icon--cant-cashout {
        opacity: .2;
    }

    .match-details__market {
        background-color: #2D2D2D;
        border: 1px solid #393939;
    }

    .fancy-bets-slider-wrp {
        border-top: 1px solid #393939;
        background-color: #393939;
    }

    .fancy-bets-slider-name {
        color: #fff;
    }

    .fancy-bets-slider:nth-of-type(2n):before {
        background-color: #393939;
    }

    .fancy-bets-slider__label {
        color: #fff;
    }

    .match-details__bets {
        &:not(:last-of-type) {
            border-bottom: 1px solid #393939;
        }
    }

    .match-details__bet {
        color: #fff;

        &.match-details__bet--active {
            background: #637858;
            box-shadow: none;
            color: #fff;

            .match-details__bet-value {
                color: #fff;
            }
        }

        &.bet--column2:nth-child(2n+1):not(:only-child) {
            box-shadow: inset -1px 0 0 0 #393939;
        }

        &.bet--column3 {
            box-shadow: inset -1px 0 0 0 #393939;
        }
    }

    .match-details__bet-value {
        color: #d06a39;
    }
}

.match-details--tablet {
    @extend %G-match-details--tablet;
}

.match-details--desktop {
    @extend %G-match-details--desktop;
}


.prematch--tablet,
.prematch--desktop {
    /* ---------------------- score board ---------------------- */

    .match-details__header-info {
        @extend %G-prematch-scoreboard--tablet--desktop;

        color: #fff;

        .match-details__header-info-competitor:after {
            background-color: #000000;
        }

        .match-details__description {
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.51);
        }
    }

    /* ---------------------- OutRights details ---------------------- */

    .outright-details {
        @extend %G-prematch-outright-details;

        .match-details__bets {
            border-left: 1px solid #393939;
        }

        .outright-details__bet {
            border-right: 1px solid #393939;
            border-bottom: 1px solid #393939;
            background-color: #2D2D2D;
            color: #fff;

            &.outright-details__bet--active {
                background: #637858;
                color: #fff;

                .outright-details__bet-value {
                    color: #fff;
                }
            }
        }

        .outright-details__bet-value {
            color: #cd5b1d;
        }
    }
}

.prematch--desktop {
    @extend %G-prematch-scoreboard--desktop;

}

.prematch--tablet {
    @extend %G-prematch-scoreboard--tablet;
}

.body--with-cursor {
    .match-details__bet {
        &:not(.match-details__bet--disabled) {
            &:hover {
                background: #637858;
                box-shadow: none;
                color: #fff;

                .match-details__bet-value {
                    color: #fff;
                }
            }
        }
    }

    /* ---------------------- OutRights details ---------------------- */

    .outright-details .outright-details__bet:hover {
        background: #637858;
        color: #fff;

        .outright-details__bet-value {
            color: #fff;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.3 PREMATCH RIGHT CONTENT (COUPONS)
|--------------------------------------------------------------------------
*/

.coupons__section {
    @extend %G-coupons__section--desktop;
}

/*
|--------------------------------------------------------------------------
| == 3.1.m PREMATCH PAGE(mobile)
|--------------------------------------------------------------------------
*/
.prematch--mobile {
    @extend %G-prematch--mobile;

    .prematch__content--with--panel-opened {
        height: $size-height__page-content-100vh_mobile;
        overflow: hidden;
    }

    .prematch__loading--mobile,
    .ll-preloader__prematch-detail {
        min-height: calc(100vh - #{$size-height--footer_mobile} - #{$size-height--header_mobile} - #{$size-height--header-nav-menu_mobile} - 7rem);
        background: #2D2D2D;
    }

    /* ----------------- Mobile Filters Panel ----------------- */

    .prematch__filters--mobile {
        background-color: #272727;
    }

    .prematch__filters-search-btn {
        border-color: #393939;
        background-color: #272727;
        color: #FFFFFF;
    }

    /* ----------------- Popular Tournaments ----------------- */

    .popular-tournaments {
        background-color: #DDDDDD;

        .popular-tournaments__list-item {
            background: #7C7C7C;
            color: #fff;
        }

        .popular-tournaments__match-count {
            background: #637858;
            color: #fff;
        }
    }

    .menu__board {
        border: 0.1rem solid #DADAD9;
        box-shadow: 3px 2px 5px #00000026;
    }

    .prematch-breadcrumbs-dropdown--mobile {
        border: none;
        box-shadow: none;
    }

    .menu__board-back {
        color: #CD5B1D;
    }

    .menu__board-sport {
        color: #000;
    }

    .menu__breadcrumbs-title {
        color: #CD5B1D;
    }

    .menu__breadcrumbs-text {
        color: #343434;
    }

    /* ---------------------- Tournament Details ---------------------- */
    .ll-tournament-detail__accordion {
        color: #fff;

        .ll-accordion__header {
            border-bottom: 0.1rem solid rgba(0, 0, 0, 0.12);
            background: #F5F5F5;
        }

        .ll-accordion__icon,
        .ll-accordion__icon--right {
            color: #cd5b1d;
        }

        .match__icon--cant-cashout {
            opacity: .2;
        }
    }

    .ll-tournament__accordion-head {
        border-bottom: 1px solid #202020;
        background-color: #2d2d2d;
        color: #e0e0e0;
    }

    .ll-tournament-detail__name {
        color: #000;
    }

    .ll-tournament-detail__matches-count {
        background: #cd5b1d;
        color: #fff;
    }

    /* ---------------------- Outrights ---------------------- */

    .ll-outright-tournament__accordion,
    .ll-outright-tournament__accordion--active {
        border: 1px solid #cccccc;
        background: #F5F5F5;

        .ll-outright-tournament__accordion-arrow {
            color: #cd5b1d;
        }
    }

    .ll-outright-tournament .match__icon--cant-cashout {
        opacity: .2;
    }

    .matches__bets-btn-value-original-odd {
        color: #ffffff80;

        &::after {
            color: #fff;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.m.1 PREMATCH MOBILE MENU
|--------------------------------------------------------------------------
*/

.prematch--mobile {
    background-color: #ffffff;

    .menu-item__after {
        color: #fff;
    }

    .menu-item__after-pre-count {
        background: #637858;
    }

    .menu-item__after-out-count {
        background: #cd5b1d;

        &::before {
            border-bottom: 2.3rem solid #cd5b1d;
            border-left: 1rem solid transparent;
        }
    }

    .prematch__menu {
        @extend %G-prematch__menu--mobile;

        .menu-item {
            border: 1px solid #eaeae7;
            background: #F5F5F5;
            color: #000;
        }

        .menu-item__progress-bar {
            .ll-progress-bar__value {
                background-color: #cd5b1d;
            }
        }

        .menu-item--tournament-count {
            background: #CD5B1D;
            color: #fff;
        }

        .menu-item__expand-icon {
            color: #CD5B1D;
        }

        /* ---------------------- Search ---------------------- */

        .prematch-menu__search-results {
            .matches__item {
                background: #272727;
            }
        }

        .prematch-menu__search-title {
            background: #272727;
            color: #fff;
        }
    }
}

/* ---------------------- Ranged Events ---------------------- */
.ranged-events--mobile {
    @extend %G-ranged-events--mobile;

    .ranged-events__back-btn {
        color: #CD5B1D;
    }

    .ranged-events__tops-title {
        border-bottom: 1px solid #C5C5C5;
        background: #7C7C7C;
        color: #fff;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.m.3 MATCH DETAILS
|--------------------------------------------------------------------------
*/
.match-details--mobile,
.prematch--mobile .outright-details {
    @extend %G-sport-outright-match-details--mobile;

    .match-details__header-match-name {
        color: #fff;
    }
}

.match-details__title {
    @extend %G-match-details__title;
    background-color: #393939;
    color: #DDDDDD;

    & > *:not(:last-child)::after {
        background-color:  #DDDDDD;
    }
}

.match-details--mobile {
    @extend %G-match-details--mobile;

    .match-details__error {
        background: #fff;
    }

    .match-details__market {
        background-color: #393939;
        border-bottom: 2px solid #272727;
    }

    /* ---------------------- Scoreboard ---------------------- */

    .match-details__header-info {
        background-color: #2D2D2D;
    }

    .match-details__competitor-name,
    .match-details__competitor-without-img-icon {
        color: #fff;
    }

    .match-details__description {
        color: #fff;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.51);
    }

    /* ---------------------- Markets ---------------------- */

    .match-details__market-header {
        color: #fff;
    }

    .match-details__view-switcher {
        &::before,
        &::after {
            fill: #fff;
        }
    }

    .market__icon--cant-cashout {
        opacity: .2;
    }

    .match-details__bets {
        border-bottom: 2px solid transparent;

        &:last-of-type {
            border-bottom: none;
        }
    }

    .match-details__bet {
        background: #303030;
        color: #DDDDDD;

        &.bet--column2:nth-child(2n+1):not(:only-child) {
            box-shadow: inset -2px 0 0 0 #393939;
        }

        &.bet--column3 {
            box-shadow: inset -2px 0 0 0 #393939;
        }
    }

    .match-details__bet-value {
        color: #DA6120;
    }

    .match-details__bet--active {
        background: #cd5b1d;
        color: #fff;

        .match-details__bet-value {
            color: #fff;
        }
    }
}

.prematch--mobile .outright-details {
    @extend %G-outright-details--mobile;

    .ll-outright__title {
        color: #fff;
    }

    .outright-details__item {
        background: #2d2d2d;
    }

    .outright-details__bet {
        background: #393939;
        color: #FF6D1E;
    }

    .outright-details__bet--active {
        background: #cd5b1d !important;
    }
}

.prematch__empty-data,
.prematch-details__empty-data {
    background: #2D2D2D;
    color: #FF6D1E;
}

.prematch-mobile__empty-data {
    background: #272727;
}
.prematch-mobile__empty-data-msg {
    background: #2D2D2D;
    color: #FF6D1E;
}
