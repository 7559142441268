.ll-popup {
    bottom: calc(#{$size-height--footer_desktop} + 1rem);
    box-shadow: 0 3px 6px #00000029;
    color: #2D2D2D;

    &.ll-popup--tablet {
        bottom: calc(#{$size-height--footer_mobile} + 1rem);
    }

    &.ll-popup--battle-upper {
        bottom: calc(#{$size-height--footer_desktop} + 10rem + 2rem);
    }

    &.ll-popup--mobile {
        bottom: calc(#{$size-height--footer_mobile} + 2rem);

        &.ll-popup--battle-upper {
            bottom: calc(#{$size-height--footer_desktop} + 10rem + 3rem);
        }
    }

    &.ll-popup--warning {
        .ll-popup__type {
            background-color: #FF0000;
            color: #fff;
        }
    }

    &.ll-popup--battle {
        .ll-popup__type {
            background-color: #CD5B1D;
            color: #fff;

            &.animated {
                background-color: #ed1c24;
            }
        }

        .ll-btn2.slots-battles__btn--accent:hover {
            opacity: 1;
            color: #000;

            &:before {
                background: #e7966a;
            }
        }

        .ll-btn2.slots-battles__btn--outlined {
            color: #7C7C7C;

            &:before {
                box-shadow: inset 0 0 0.1rem 0.1rem #7C7C7C;
            }

            &:hover {
                color: #000;
            }
        }

        .ll-popup__invitation_info {
            .ll-popup__icon {
                color: #393939;
            }

            .ll-popup__invitation-info-part-border:after {
                background-color: #7C7C7C;
            }
        }

        .ll-popup__battle-info-item::after {
            background-color: #393939;
        }

        .ll-popup__buttons .ll-popup__button--gray {
            background-color: transparent;
            border: 0.1rem solid #7C7C7C;
            color: #7C7C7C;
        }
    }

    .ll-popup__header-icon.ll-popup__header-icon--warning {
        color: #FF0000;
    }

    .ll-popup__header-icon.ll-popup__header-icon--battle {
        color: #CD5B1D;
    }

    .ll-popup__type {
        background-color: #637858;
        color: #2D3628;
    }

    .ll-popup__inner {
        background-color: rgba(255, 255, 255, 0.85);
    }

    .ll-popup__time {
        color: #717171;
    }

    .ll-popup__timer {
        color: #CD5B1D;
    }
}

.body--with-cursor {
    .ll-popup.ll-popup--link {
        &:hover {
            box-shadow: 0px 0px 16px 1px #cd5b1d77;
        }
    }
}

body:not(.body--with-cursor) {
    .ll-popup.ll-popup--link {
        box-shadow: 0px 0px 16px 1px #cd5b1d77;
    }
}

/*
|--------------------------------------------------------------------------
| == ACTIVE BETS & MY BETS DESKTOP POPOVER
|--------------------------------------------------------------------------
*/

.user-bets__popper-cashout {
    @extend %G-user-bets__popper-cashout;
    background-color: #fff;
    border: 1px solid #CD5B1D;
    color: #000;

    &:before {
        border: 1px solid #CD5B1D;
        background: #2D2D2D;
    }

    .user-bets__popper-cashout-message {
        background: #2D2D2D;
        color: #fff;
    }
}

.user-bets__popper-delete {
    @extend %G-user-bets__popper-delete;

    background-color: #fff;
    border: 1px solid #CD5B1D;
    color: #000;

    &:before {
        border: 1px solid #CD5B1D;
        background: #2D2D2D;
    }

    .user-bets__popper-delete-message {
        background: #2D2D2D;
        color: #fff;
    }
}

