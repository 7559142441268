/*
3.PAGES
└── 3.6. MY ACCOUNT PAGE
    ├── 3.6.1 MY INFORMATION
    ├── 3.6.2 PAYMENTS
    ├── 3.6.4 ACCOUNT HISTORY
    ├── 3.6.5 BET HISTORY
    ├── 3.6.6 CASINO REPORT
    ├── 3.6.7 MY LIMITS
    └── 3.6.8 GOOGLE AUTHORIZATION
*/

/*
|--------------------------------------------------------------------------
|  == 3.6. MY ACCOUNT PAGE
|--------------------------------------------------------------------------
*/

.my-account--desktop,
.my-account--tablet,
.my-account--mobile {
    color: #000;

    @extend %G-my-account;

    .bet-delete-icon {
        color: #CD5B1D;
    }
}

.my-account--tablet,
.my-account--mobile {
    @extend %G-my-account--mobile;

    .data-table--mobile {
        .dx-datagrid-headers td {
            background: #fff;
            color: #cd5b1d;
        }
    }

    .my-account-section {
        border: 1px solid #393939;
    }

    .my-account-section__title {
        width: 100%;
        height: 3rem;
        background: #393939;
        color: #fff;
    }

    .my-account-section--secondary {
        border: 1px solid #888888;

        .my-account-section__title {
            background: #888888;
        }
    }
}

.my-account__title--mobile {
    @extend %G-my-account__title--mobile;
    background: #888888;
    color: #fff;
}

.my-account--tablet,
.my-account--desktop {
    @extend %G-my-account--tablet-desktop;
}

.my-account--tablet {
    @extend %G-my-account--tablet;
}

.my-account--desktop {
    @extend %G-my-account--desktop;

    .account-menu_link-icon {
        fill: rgba(0, 0, 0, 0.65);
    }

    .my-account__title {
        color: #fff;
        background: #393939;
        box-shadow: 0 0 5px 0 #00000020;
    }
}

.my-account-drawer {
    @extend %G-my-account-drawer;

    .my-account-drawer__blocked-user {
        color: #cd5b1d;
    }

    .my-account-drawer__menu-list {
        background: #637858;
    }

    .my-account-drawer__menu-link {
        color: #fff;

        &.active,
        &:hover {
            background: #2D3628;
        }
    }

    .my-account-drawer__close-btn {
        &:hover {
            color: #cd5b1d;
        }
    }
}

.my-account-info-list {
    @extend %G-my-account-info-list;

    .my-account-info-list__item {
        border-bottom: 1px solid #393939;
        background: #F5F5F5;
        color: #000;

        &:nth-child(2n) {
            background: #fff;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.1 MY ACCOUNT PAGE -> MY INFORMATION
|--------------------------------------------------------------------------
*/

.my-account--tablet,
.my-account--mobile {
    .my-info {
        @extend %G-my-info--mobile;
    }
}

.my-info__wrapper--tablet,
.my-info__wrapper--mobile {
    @extend %G-my-info__wrapper--mobile;

    .my-info__change-pass {
        color: #637858;
    }

    .my-account-info-list__item:last-of-type {
        border: none;
    }
}

.my-info__wrapper--tablet {
    @extend %G-my-info__wrapper--tablet;
}

.my-info__wrapper--desktop {
    @extend %G-my-info__wrapper--desktop;

    .my-info__coll {
        background: #fff;
    }

    .my-info__sub-title {
        background: #515151;
        color: #fff;
    }

    .my-info__row {
        border-bottom: 1px solid #515151;
        background: #2D2D2D;
        color: #fff;

        &:last-of-type {
            border-bottom: none;
        }
    }

    .my-info__cell-link {
        background-color: transparent;
        color: #ff5a00;
        text-shadow: 0 0 0 #ff5a00;

        &:hover,
        &:focus,
        &:active {
            text-shadow: 0 0 7px #ff5a00;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.2 PAYMENTS
|--------------------------------------------------------------------------
*/
.payments {
    @extend %G-payments;

    .payment__btn-link-text {
        color: #fff;
    }

    .payment__btn-link-name {
        color: #2D2D2D;
    }
}

.payments__content--mobile,
.payments__content--desktop {
    @extend %G-payments-btn-link;

    .payment {
        box-shadow: 3px 2px 5px #00000038;
        background-color: #fff;
    }
}

.payments__content--mobile {
    .payment {
        border: 1px solid #cd5b1d;
    }
}

.payments__content--desktop {
    .payments__no-data {
        background: #fff;
    }

    .payment {
        border: 1px solid #779866;
    }
}

.payment-modal {
    @extend %G-payment-modal;

    color: #2d2d2d;
}

/*
|--------------------------------------------------------------------------
| == 3.6.4 ACCOUNT HISTORY
|--------------------------------------------------------------------------
*/

.my-account--mobile .account-history {
    .my-account-info-list {
        border-right: 1px solid #393939;
        border-left: 1px solid #393939;
    }
}

.account-report--mobile {
    @extend %G-account-report--mobile;

    border: 1px solid #393939;
    background: #393939;
    color: #fff;

    .account-report-item:not(:last-of-type),
    .account-report-item--active:not(:last-of-type) {
        border-right: 1px solid #888888;
    }

    .account-report-item--active {
        color: #cd5b1d;
    }

    .ll-burger-icon--opened {
        div {
            background-color: #cd5b1d;
        }
    }
}

.account-report--tablet,
.account-report--desktop {
    @extend %G-account-report--desktop;

    .account-report-item {
        background: #2D2D2D;
    }

    .my-account-info-item__content--overflow {
        background: #2D2D2D;

        &.expanded {
            box-shadow: 0 2px 8px 0px #0000002b;
        }
    }

    .my-account-info-item__title {
        background: #515151;
        color: #fff;
    }

    .my-account-info-list__item {
        border-bottom: 1px solid #515151;
        background: #2D2D2D;
        color: #fff;
    }

    .my-account-info-list__item-value--negative {
        color: #f16969;
    }
}

.account-report--tablet {
    .account-report-item {
        border: 1px solid #C5C5C5;
    }

    .my-account-info-item__title {
        background: #393939;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.5 BET HISTORY
|--------------------------------------------------------------------------
*/
.bet-history--mobile,
.bet-history--tablet {
    @extend %G-bet-history--mobile-tablet;

    .bet-history__status-active-bets--disabled,
    .bet-history__status-filter--disabled {
        color: #C5C5C5;
    }

    .bet-history__status-filter {
        .ll-dropdown-menu__icon,
        .ll-dropdown-menu__icon--opened {
            color: #fff;
        }

        .bet-history__status-filter-dropdown {
            background: #f5f5f5;
            border: 1px solid #888888;
            border-top: none;

            .ll-dropdown-menu-item,
            .ll-dropdown-menu-item--disabled,
            .ll-dropdown-menu-item--active {
                color: #2D2D2D;

                &:not(:last-of-type) {
                    border-bottom: 1px solid #888888;
                }
            }

            .ll-dropdown-menu-item--disabled {
                color: #b7b6b6;
            }

            .ll-dropdown-menu-item--active {
                color: #CD5B1D;
            }
        }
    }
}

.bet-history--tablet {
    @extend %G-bet-history--tablet;
}

.bet-history--desktop {
    @extend %G-bet-history--desktop;

    .bet-history__cashout-cell-btn {
        background: #d06a39;
        color: #fff;
    }

    /* ---------------------- Bet details in table ---------------------- */

    .bet-history-detail__info {
        background-color: #393939;
        color: #fff;
    }

    .bet-history-detail__info-row {
        border: 1px solid #515151;
        border-top: none;
    }

    .bet-history-detail__info-td {
        &:not(:last-child)::after {
            background-color: #7C7C7Caa;
        }
    }
}

.body--with-cursor {
    .bet-delete-icon:hover {
        color: #BC4D11;
    }

    .bet-history__cashout-cell-btn:hover {
        box-shadow: inset 0 0 1px 1px #d06a39;
        background: transparent;
        color: #d06a39;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.6 CASINO REPORT
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 3.6.7 MY LIMITS
|--------------------------------------------------------------------------
*/

.my-limits {
    @extend %G-my-limits;

    .my-limits__provider-limit-info-icon {
        color: #637858;
    }
}

.my-limits--mobile,
.my-limits--tablet {
    @extend %G-my-limits--mobile-table;

    .my-limits__content-box--rows .my-limits__content-box-title {
        background-color: #393939;
        color: #fff;
    }

    .my-limits__content-box--info .my-limits__content-box-title {
        color: #637858;
    }

    .my-limits__content-row {
        border: 1px solid #393939;
        border-top: none;

        &:nth-child(2n + 1) {
            background-color: #f5f5f5;
        }

        &:nth-child(2n) {
            background-color: #fff;
        }
    }
}

.my-limits--desktop {
    @extend %G-my-limits--desktop;

    .my-limits__content-box {
        box-shadow: 0 0 5px 0 #00000020;
    }

    .my-limits__content-box-title {
        background: #515151;
        color: #fff;
    }

    .my-limits__content-box-wrp {
        background-color: #2D2D2D;
        color: #fff;
    }

    .my-limits__content-row {
        &:not(:last-child) {
            border-bottom: 1px solid #515151;
        }

        & > * {
            &:first-child {
                border-right: 1px solid #515151;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.8 MY ACCOUNT PAGE -> GOOGLE AUTHORIZATION
|--------------------------------------------------------------------------
*/
.google-auth {
    @extend %G-google-auth;

    .google-auth__content {
        border: 1px solid #d6d6d6;
        background: #f5f5f5;
    }

    .google-auth__img-qr {
        @include size(
                        $size-width--cabinet-google-auth-img + px,
                        $size-height--cabinet-google-auth-img + px
        );
    }

    .google-auth__qr-img-container {
        border-bottom: 1px solid #d6d6d6;
    }

    .google-auth__install-app {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .google-auth__form-password,
    .google-auth__form-password--mobile {
        @extend %G-google-auth__form-password;
    }

    .google-auth__form-password--mobile {
        @extend %G-google-auth__form-password--mobile;
    }

    .google-auth__form-qr-code,
    .google-auth__form-qr-code--mobile {
        @extend %G-google-auth__form-qr-code;
    }

    .google-auth__form-qr-code--mobile {
        @extend %G-google-auth__form-qr-code--mobile;
    }
}

.my-account--mobile .google-auth {
    @extend %G-google-auth--mobile;
}

/*
|--------------------------------------------------------------------------
| == 3.6.9 MY ACCOUNT PAGE -> MESSAGES PAGE
|--------------------------------------------------------------------------
*/

.messages {
    @extend %G-messages;

    .message-item {
        background-color: #393939;
        color: #fff;
    }

    .message-date {
        color: #888888;
    }

    .message-unread {
        background-color: #515151;

        .message-date {
            color: rgba(0, 0, 0, 0.65);
        }
    }
}

.messages--mobile,
.messages--tablet {
    @extend %G-messages--mobile;
}

.messages--desktop {
    @extend %G-messages--desktop;
}