/*
3.PAGES
└── 3.1.2. LIVE PAGE
    ├── 3.1.2.1. LIVE WIDGETS
    ├── 3.1.2.2. LIVE MENU (DESKTOP & TABLET)
    ├── 3.1.2.3. MATCH DETAILS (DESKTOP & TABLET)
    ├── 3.1.2.4. LIVE PAGE (MOBILE)
    ├───├── 3.1.2.4.1. LIVE PAGE MENU
    ├───└── 3.1.2.4.2. LIVE PAGE DETAILS
    └── 3.1.2.5. LIVE CALENDAR
*/

/*
|--------------------------------------------------------------------------
| == 3.1.2.1. LIVE WIDGETS
|--------------------------------------------------------------------------
*/

.live-sticky-widget {
    top: calc(#{$size-height--header_mobile} + #{$size-height--header-nav-menu_mobile});

    @include mq('tablet') {
        top: calc(#{$size-height--header_tablet} + #{$size-height--header-nav-menu_tablet});
    }
}

/* --------------- Live widgets navigation tabs --------------- */
.live-widgets__nav--mobile {
    background-color: #393939;

    .live-widgets__nav-item {
        color: #fff;
    }
}

.live-widgets__nav--tablet,
.live-widgets__nav--desktop {
    background-color: #2d2d2d;

    .live-widgets__nav-item {
        color: #fff;

        .live-widgets__nav-icon {
            color: #fff;
        }
    }

    .live-widgets__nav-item--active {
        color: #f60;

        .live-widgets__nav-icon {
            color: #f60;
        }
    }

    .live-widgets__nav-info {
        color: #fff;

        &:hover {
            color: #f60;
        }
    }
}

.live-widgets__nav--desktop {
    .live-widgets__nav-item:hover {
        color: #f60;

        .live-widgets__nav-icon {
            color: #f60;
        }
    }
}

.live-widgets__nav--tablet {
    border-bottom: 1px solid #fff;
}

.live-widgets {
    .live-widgets__nav-info-text {
        background: #393939;
        color: #fff;
        border: 1px solid #7C7C7C;
    }
}

.prematch--mobile {
    .live-widgets__nav-container {
        border: 0.1rem solid #393939;
        box-shadow: 3px 2px 5px #00000026;
        background: #393939;

        .live-widgets__nav-item {
            border-right: 1px solid #2d2d2d;
        }

        .ll-burger-icon {
            background: #393939;

            div {
                background-color: #fff;
            }
        }

        .live-widgets__nav-info {
            background: #393939;
            color: #fff;
        }

        .ll-burger-icon--opened {
            background: #393939;

            div {
                background-color: #fff;
            }
        }
    }
}

/* ------------ Live widgets navigation tabs END ------------ */

/* -------------------- Stream widget -------------------- */
.stream {
    .stream__loader-box {
        background: #2d2d2d;
    }

    .stream__error-content {
        background: #2d2d2d;
    }

    .stream__error-text {
        color: #fff;
    }
}

.livematches__view-buttons {
    color: #fff;
    background-color: #393939;
}

.livematches__view-button--active {
    background-color: #2d2d2d;
    color: #cd5b1d;
}

.prematch--desktop {
    .livematches__view-button:hover {
        background-color: #2d2d2d;
        color: #cd5b1d;
    }
}

/* ------------------- Stream widget END ------------------- */


/*
|--------------------------------------------------------------------------
| == 3.1.2.2. LIVE MENU (DESKTOP & TABLET)
|--------------------------------------------------------------------------
*/

.live-menu__loading {
    background-color: rgba(0, 0, 0, 0.2);
}

.live-menu__section {
    min-width: $size-width__livematch-menu--desktop-1360;
    max-width: $size-width__livematch-menu--desktop-1360;

    @include mq('(min-width: 1500px)') {
        min-width: $size-width__livematch-menu--desktop-1500;
        max-width: $size-width__livematch-menu--desktop-1500;
    }

    @include mq('(min-width: 1700px)') {
        min-width: $size-width__livematch-menu--desktop-1700;
        max-width: $size-width__livematch-menu--desktop-1700;
    }

    & + .prematch__loading--initial {
        background-color: rgba(0, 0, 0, 0.2);
    }

    &.live-menu__section--disabled:before {
        background: #ffffff50;
    }

    .live-left-menu__odds-count {
        background-color: #000;
    }

    .ll-accordion .ll-accordion__header,
    .ll-accordion--active .ll-accordion__header {
        background-color: #2d2d2d;
    }

    .live-common-menu__item-name {
        border-bottom: 1px solid #393939;
        background-color: #2d2d2d;
        color: #cd5b1d;
    }

    .ll-accordion__header {
        border-bottom: 1px solid #000;
        background: #3e3e3e;
    }

    .ll-accordion__header-count {
        color: #DADAD9;
        background-color: #535353;
    }

    .ll-accordion__header-text {
        color: #fff;
    }

    .live-left-menu__item {
        background-color: #2d2d2d;
        color: #fff;
    }

    .match__icon--can-cashout,
    .match__icon--cant-cashout {
        color: #fff;
    }

    .match__icon--cant-cashout {
        opacity: .2;
    }

    .live-left-menu__list-row,
    .live-left-menu__list-row--active {
        &:not(:first-of-type) {
            box-shadow: inset 0 1px 0 0 #383838;
        }

        &:first-of-type:last-of-type {
            box-shadow: inset 0 -1px 0 0 #383838;
        }

        &:not(:first-of-type):last-of-type {
            box-shadow:
                inset 0 1px 0 0 #383838,
                inset 0 -1px 0 0 #383838;
        }

        .live-left-menu__bet {
            box-shadow: inset -1px 0 0 0 #313131;
        }

        .live-left-menu__bet--active {
            background: #cd5b1d !important;

            .live-left-menu__bet-value:after {
                color: #fff;
            }
        }
    }

    .live-match__competitors-holder {
        box-shadow: inset -1px 0 0 0 #313131;
    }

    .live-left-menu__bet {
        box-shadow: inset 1px 0 0 0 #313131, inset -1px 0 0 0 #313131;
    }

    .live-left-menu__row-header {
        color: #ccc;
        background-color: #383838;
    }

    .live-left-menu__row-header-competitors {
        box-shadow: inset -1px 0 0 0 #313131;
    }

    .live-left-menu__oddsv {
        box-shadow: inset 1px 0 0 0 #313131, inset -1px 0 0 0 #313131;
    }

    .live-left-menu__header {
        background-color: #2d2d2d;
        color: #f60;
    }

    .live-left-menu__bet--dn {
        .live-left-menu__bet-value:after {
            color: #d10307;
        }
    }

    .live-left-menu__bet--up {
        .live-left-menu__bet-value:after {
            color: #abf210;
        }
    }
}

.body--with-cursor {
    .live-common-menu__item-name:hover {
        background-color: #272727;
    }
}

.prematch--desktop {
    .ll-accordion__header:hover {
        background-color: #637858;

        .ll-accordion__header-count {
            color: #fff;
        }
    }

    .live-left-menu__list-row,
    .live-left-menu__list-row--active {
        .live-left-menu__bet:hover {
            background: #cd5b1d !important;

            .live-left-menu__bet-value:after {
                color: #fff;
            }
        }

        &:hover {
            background: #637858;

            .live-left-menu__bet-value:after {
                color: #fff;
            }
        }
    }

    .live-left-menu__list-row--active {
        background: #637858;

        .live-left-menu__bet {
            .live-left-menu__bet-value:after {
                color: #fff;
            }
        }
    }
}

.prematch--tablet {
    .live-left-menu__view-btn,
    .live-details__calendar-btn {
        background-color: #2d2d2d;
        color: #fff;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.3. MATCH DETAILS (DESKTOP & TABLET)
|--------------------------------------------------------------------------
*/
.live-detail__section {
    .match-details__header-info-competitor:after {
        background-color: #000000;
    }

    .match-details__header-info {
        background-color: #999999;
        color: #fff;
    }

    .match-details__competitor-without-img {
        .match-details__breadcrumbs-icon {
            color: white
        }
    }

    .match-details__competitor-name {
        color: #fff;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.51);
    }

    .match-details__description {
        color: #fff;
    }
}

.live-market-groups__slider {
    background-color: #2d2d2d;
    border-bottom: 1px solid #393939;

    .market-groups__slider-arrow {
        color: #fff;
    }

    .live-match-details__market-group,
    .live-match-details__market-group--active {
        color: #fff;
    }

    .live-match-details__market-group--active {
        background-color: #CD5B1D;
        color: #fff;
    }
}

.prematch--desktop {
    .live-match-details__market-group:hover {
        @include mq('desktop') {
            background-color: #CD5B1D;
            color: #fff;
        }
    }
}

.live-match-details {
    color: #fff;

    .match-details__market-header {
        border-bottom: .1rem solid #202020;
        background-color: #343434;
    }

    .breadcrumbs__tournament:hover {
        color: #d06a39;
    }

    .match-details__markets-error {
        color: #000;
    }

    .match-details__markets {
        background-color: #2d2d2d;
    }
    
    .fancy-bets-slider-wrp {
        background-color: #393939;
    }

    .fancy-bets-slider:nth-of-type(2n):before {
        background-color: #DADAD9;
    }

    .match-details__bet {
        box-shadow: inset -1px 0 0 0 #383838, inset 0 -2px 1px -1px #383838;

        &:not(.match-details__bet--disabled) {
            &:active,
            &:focus,
            &:hover {
                background: #637858;
                color: #fff;

                .match-details__bet-value,
                .match-details__bet-value:after {
                    color: #fff;
                }
            }
        }

        &.match-details__bet--active {
            background: #637858;
            color: #fff;

            .match-details__bet-value,
            .match-details__bet-value:after {
                color: #fff;
            }
        }
    }

    .match-details__bet--dn {
        .match-details__bet-value:after {
            color: #d10307;
        }
    }

    .match-details__bet--up {
        .match-details__bet-value:after {
            color: #abf210;
        }
    }

    .match-details__bet-value {
        color: #d06a39;
    }

    .match-details__bets--contains-3 {
        .match-details__bet:nth-child(3n),
        .match-details__bet:last-child {
            box-shadow: inset 0 -2px 1px -1px #383838;
        }
    }

    .match-details__bets--contains-2 {
        .match-details__bet:nth-child(2n),
        .match-details__bet:last-child {
            box-shadow: inset 0 -2px 1px -1px #383838;
        }
    }

    .match-details__bets {
        background: #202020;
    }

    .market__icon--cant-cashout {
        opacity: .4;
    }
}

.live-match-details__landscape {
    .live-page__landscape-scrollbox {
        background: #2d2d2d;
    }
}


/*
|--------------------------------------------------------------------------
| == 3.1.2.4. LIVE PAGE (MOBILE)
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| == 3.1.2.4.1. LIVE PAGE MENU
|--------------------------------------------------------------------------
*/

.livematches {
    .livematches__view-selectors {
        background-color: #888888;
        color: #fff;
    }

    .livematches__view-button--active {
        background-color: #405337;
    }

    .livematches__sport-item {
        color: #000;
        border: 1px solid #eaeae7;
        background: #F5F5F5;
    }

    .livematches__sport-match-count {
        background: #888888;
        color: #fff;
    }

    .livematches__category-name {
        border-bottom: 1px solid #2d2d2d;
        background-color: #393939;
        color: #fff;
    }

    .livematches__match-items {
        background: #2d2d2d;
        border-bottom: 1px solid #393939;
        color: #fff;
    }

    .livematch__info {
        .livematch__info-score {
            color: #7EA46A;
        }

        .livematch__info-time,
        .livematch__info-status {
            color: #DADAD9;
        }
    }

    .match__icon--can-cashout,
    .match__icon--cant-cashout {
        color: #DADAD9;
    }

    .match__icon--cant-cashout {
        opacity: .4;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.4.2. LIVE PAGE DETAILS
|--------------------------------------------------------------------------
*/

.livematches {
    .match-details__competitor-without-img {
        .match-details__breadcrumbs-icon {
            color: #fff;
        }
    }

    .match-details__header-info-competitor:after {
        background-color: #000000;
    }

    .match-details__competitor-name {
        color: #fff;
    }

    .match-details__description {
        color: #fff;
    }

    .live-match-details__market-group {
        background-color: #2d2d2d;
    }

    .match-details__empty-markets {
        background-color: #2d2d2d;
        color: #fff;
    }

    .matches__bets-btn--dn,
    .match-details__bet--dn {
        .matches__bets-btn-value-odd:after,
        .match-details__bet-value-odd:after {
            color: #d10307;
        }
    }

    .matches__bets-btn--up,
    .match-details__bet--up {
        .matches__bets-btn-value-odd:after,
        .match-details__bet-value-odd:after {
            color: #abf210;
        }
    }

    .live-widgets__nav-item--active {
        color: #f60;

        .live-widgets__nav-icon {
            color: #f60;
        }
    }

    .match-details__bet--active .match-details__bet-value-odd:after,
    .matches__bets-btn--active .matches__bets-btn-value-odd:after {
        color: #fff !important;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.1.2.5. LIVE CALENDAR
|--------------------------------------------------------------------------
*/

.live-calendar {
    .live-calendar__back-button,
    .live-calendar__title {
        background: #2d2d2d;
    }

    .live-calendar__back-button {
        color: #fff;
    }

    .live-calendar__title {
        color: #cd5b1d;
    }

    .live-calendar__preloader-wrapper {
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.prematch--tablet {
    .live-details__header > *:not(:last-child) {
        background-color: #2d2d2d;
    }

    .live-details__back-button {
        color: #f60;
    }
}

.prematch--mobile {
    .live-calendar__no-data-text {
        border: 1px solid #d6d6d6;
        background: #f5f5f5;
    }
}