/*
2. LAYOUT
├── 2.1. COMMON LAYOUT COMPONENTS
│   ├── 2.1.1 SOCIAL ICONS
│   └── 2.1.2 LICENSE ICONS
├── 2.2. FOOTER
│   ├── 2.2.1 FOOTER DESKTOP
│   └── 2.2.2 FOOTER MOBILE
├── 2.3. CONTENT
│   ├── 2.3.1 CONTENT FOOTER
├── 2.4. HEADER
│   ├── 2.4.1 HEADER DESKTOP
│   ├── 2.4.2 HEADER MOBILE
│   ├── 2.4.3 HEADER MENU
│   └── 2.4.4 HEADER LANGUAGE PICKER
├── 2.5. MODAL
│   ├── 2.5.1 MODAL DESKTOP
│   └── 2.5.2 MODAL MOBILE
├── 2.6. DRAWER
│   ├── 2.6.1 GAME MENU DRAWER
│   ├── 2.6.2 SUPPORT MENU DRAWER
│   ├── 2.6.3 MY ACCOUNT MENU DRAWER
│   └── 2.6.4 LOGIN DRAWER
├── 2.7. POPOVER
└── 2.8. BACKDROP
*/

/*
|--------------------------------------------------------------------------
| == 2.LAYOUT
|--------------------------------------------------------------------------
*/

.ll-page {
    @extend %G-ll-page;
}

.page-wrapper--desktop {
    @extend %G-page-wrapper--desktop;
}

.page-wrapper__children {
    @extend %G-page-wrapper__children;
}

.ll-content-container-scroll {
    @extend %G-ll-content-container-scroll;
}

.ll-full-width-content-wrapper {
    @extend %G-ll-full-width-content-wrapper;
}

/*
|--------------------------------------------------------------------------
| == 2.1. COMMON LAYOUT COMPONENTS
| == 2.1.1 SOCIAL ICONS
|--------------------------------------------------------------------------
*/

.ll-social-icons--desktop,
.ll-social-icons--tablet,
.ll-social-icons--mobile {
    @extend %G-ll-social-icons;
}

/*
|--------------------------------------------------------------------------
| == 2.1.2 LICENSE ICONS
|--------------------------------------------------------------------------
*/
.ll-license-icons {
    @extend %G-ll-license-icons;

    .ll-license-icons--circle-icon {
        height: 4rem;
        background: url('../../../../static/themes/theme/images/footer/footer_tiny_circle.png') no-repeat center center;
    }

    .ll-license-icons__icon,
    .ll-license-icons__icon--desktop {
        background-color: initial;
        background-size: contain;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.2. FOOTER
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| == 2.2.1 FOOTER DESKTOP
|--------------------------------------------------------------------------
*/

.ll-footer--desktop {
    @extend %G-ll-footer--desktop;

    min-height: $size-height--footer_desktop;
    max-height: $size-height--footer_desktop;
    background: #2d3628;

    .ll-footer__icon {
        background: #709866;
    }

    .ll-footer__btn:hover .ll-footer__icon {
        color: #cd5b1d;
    }

    .ll-footer__link {
        color: #fff;
    }

    .ll-footer__btn {
        color: #fff;

        &.ll-footer__link--active::before {
            border-right: 7px solid transparent;
            border-top: 7px solid #405337;
            border-left: 7px solid transparent;
        }

        &:hover {
            background: #cd5b1d;
            box-shadow: none;

            .footer-featured__background-img-blur {
                background: #cd5b1d;
            }
        }
    }

    .ll-footer__logo {
        background: url('../../../../static/themes/theme/images/footer/monochrome_logo.svg') no-repeat center;
    }

    .footer-featured__background-img-blur {
        background: radial-gradient(#63785860 0%, #637858 70%);
    }
}

/*
|--------------------------------------------------------------------------
| == 2.2.2 FOOTER MOBILE
|--------------------------------------------------------------------------
*/

.ll-footer--mobile {
    @extend %G-ll-footer--mobile;

    min-height: $size-height--footer_mobile;
    max-height: $size-height--footer_mobile;
    border-top: 1px solid #393939;
    background-color: #2D2D2D;

    .slider__arrow {
        color: #fff;
    }

    .ll-footer__item {
        color: #fff;

        &.ll-footer__item--featured.ll-footer__item--active {
            color: #CD5B1D;
        }
    }

    .ll-footer__money-item-part:first-of-type:after {
        background: #7C7C7C;
    }

    .footer-featured__background-img-blur {
        background: radial-gradient(#2D2D2D85 0%, #2D2D2D 70%);
    }
}

/*
|--------------------------------------------------------------------------
| == 2.3 CONTENT
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| == 2.3.1 CONTENT FOOTER
|--------------------------------------------------------------------------
*/
.ll-content__footer {
    @extend %G-ll-content__footer;
    height: $size-height--content-footer;

    background: #1a1f17;
    color: #fff;

    .footer-menu__item {
        color: #D6D6D6;
    }

    .footer-license__link {
        color: #D6D6D6;
    }

    .footer-license__icon--desktop {
        color: #708E61;
    }
}

.body--with-cursor {
    .ll-content__footer {
        .footer-menu__item:hover,
        .footer-menu__support-mail-link:hover,
        .footer-license__link:hover {
            color: #ff5a00;

            .footer-license__icon--desktop {
                color: #ff5a00;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4. HEADER
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 2.4.1 HEADER DESKTOP
|--------------------------------------------------------------------------
*/
.ll-header--desktop {
    @extend %G-ll-header--desktop;

    background-color: #0E0E10;

    .ll-header__top {
        height: $size-height--header_desktop;
    }

    .ll-header-btn {
        background: #405337;

        &:hover {
            background: #CD5B1D;
        }

        &.ll-header-btn--secondary {
            background: #888888;

            &:hover {
                background: transparent;
                box-shadow: inset 0 0 1px 1px #888888;
                color: #888888;
            }
        }

        &.ll-header-btn--accent {
            background: #CD5B1D;

            &:hover {
                background: transparent;
                box-shadow: inset 0 0 1px 1px #CD5B1D;
                color: #CD5B1D;
            }
        }
    }

    .ll-header__logo {
        background: url('../../../../static/themes/theme/images/logos/logo.png') no-repeat;
        background-size: contain;
    }

    .ll-header__messages-counter {
        background: #CD5B1D;
    }

    .ll-header__sign-out-btn {
        border-color: #CD5B1D;
        color: #CD5B1D;
        background: transparent;

        &:hover {
            background: #CD5B1D;
            color: #fff;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4.2 HEADER MOBILE
|--------------------------------------------------------------------------
*/

.ll-header--mobile {
    @extend %G-ll-header--mobile;
    background: #fff;

    .ll-header__menu-button {
        color: #000;
    }

    .ll-header__button-icon {
        color: #cd5b1d;
    }

    .ll-header__logo {
        @include size(135px, 50px);
        background: url('../../../../static/themes/theme/images/logos/logo.png') no-repeat;
        background-size: contain;
    }

    .slider__arrow {
        color: #fff;
    }

    .active-bets__icon-count {
        background-color: #cd5b1d;
        color: #fff;
        border-color: #fff;

        &.animated {
            background-color: #ff5a00;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4.3 HEADER MENU
|--------------------------------------------------------------------------
*/

.ll-header__menu--sticky,
.slick-list__top-container--desktop,
.slick-list__top-container--tablet,
.slick-list__top-container--mobile {
    @extend %G-ll-header__menu;

    height: $size-height--header-menu_desktop;
}

.slick-list__top-container--desktop,
.slick-list__top-container--tablet,
.slick-list__top-container--mobile {
    .ll-dropdown-menu {
        color: #fff;
    }

    .slick-arrow {
        @include mq('tablet') {
            background: #2d2d2d;
        }

        &:hover {
            color: #fff;
            @include mq('tablet') {
                background: #2d2d2d;
            }
        }
    }

    .menu-item--desktop {
        &:not(:first-child):before {
            background-color: #57684e;
        }

        .menu-item__is-new-indicator {
            background: #fff;

            &:after {
                border-left: .6rem solid #637858;
            }
        }

        .menu-item__is-new-indicator-label {
            color: #637858;
        }

        &:hover,
        &.active {
            background: #2D3628;

            .menu-item__is-new-indicator:after {
                border-left: .6rem solid #2D3628;
            }
        }
    }

    .menu-item--mobile,
    .menu-item--tablet {
        .menu-item__is-new-indicator {
            background: #fff;

            &:after {
                border-left: .6rem solid #2d2d2d;
            }
        }

        .menu-item__is-new-indicator-label {
            color: #2d2d2d;
        }

        &.active {
            background: #708E61;
            box-shadow: none;

            .ll-header__item-text,
            .menu-item__icon,
            .menu-item__label,
            .ll-dropdown-menu__icon,
            .ll-dropdown-menu__text {
                color: #fff;
            }

            .menu-item__is-new-indicator:after {
                border-left: .6rem solid #708E61;
            }
        }
    }
}

.slick-list__top-container--desktop {
    @extend %G-slick-list__top-container--desktop;
    background: #637858;
}

.slick-list__top-container--tablet,
.slick-list__top-container--mobile {
    @extend %G-slick-list__top-container--mobile;

    .menu-item--desktop.active {
        background: linear-gradient(0deg, #45593b, #779967), #45593b;
        box-shadow: none;

        .ll-header__item-text,
        .menu-item__icon,
        .menu-item__label,
        .ll-dropdown-menu__icon,
        .ll-dropdown-menu__text {
            color: #fff;
        }
    }
}

.slick-list__top-container--tablet,
.slick-list__top-container--mobile {
    background: #2d2d2d;

    .slick-arrow {
        &:hover {
            color: #fff;
        }
    }
}

.ll-header__menu--sticky {
    @extend %G-ll-header__menu--sticky;
}

.header-menu-subs {
    @extend %G-header-menu-subs;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1254901961);

    &.menu-sub-item--mobile,
    &.menu-sub-item--tablet {
        background-color: #393939;
    }

    &.menu-sub-item--desktop {
        @extend %G-header-menu-subs--desktop;

        background-color: #405337;
    }
}

.header-menu-sub-item {
    @extend %G-header-menu-sub-item;

    color: #fff;
}

.ll-scrollbox--mobile .header-menu-sub-item,
.ll-scrollbox--tablet .header-menu-sub-item {
    &:not(:last-child) {
        border-bottom: 1px solid #2d2d2d;
    }
}

.ll-scrollbox--desktop .header-menu-sub-item {
    &:not(:last-child) {
        border-bottom: 1px solid #2D3628;
    }
}

.body--with-cursor {
    .header-menu-sub-item:hover {
        color: #CD5B1D;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.4.4 HEADER LANGUAGE PICKER
|--------------------------------------------------------------------------
*/

.ll-language-picker--desktop,
.ll-language-picker--tablet,
.ll-language-picker--mobile {
    @extend %G-ll-language-picker;
    color: #2D2D2D;

    .ll-language-picker__wrapper {
        background: #fff;
    }

    .ll-dropdown-menu-item--active {
        .ll-language-picker__text {
            font-weight: bold;
        }
    }
}

.ll-language-picker--desktop {
    @extend %G-ll-language-picker--desktop;
    text-transform: uppercase;
    color: #fff;

    .ll-language-picker__wrapper {
        background: #0E0E10;
        border: 1px solid #515151;
        border-radius: 14px;
        box-shadow: 0 0 1px 0 #515151 inset, 0 0 1px 0 #515151;
    }

    .ll-dropdown-menu-item,
    .ll-dropdown-menu-item--active {
        border: 1px solid #515151;
    }

    .ll-dropdown-menu-item {
        background: #0E0E10;
    }

    .ll-dropdown-menu-item--active {
        background: #0E0E10;
    }

    .ll-dropdown-menu-item:hover,
    .ll-dropdown-menu-item--active:hover,
    .ll-language-picker__wrapper:hover {
        color: #cd5b1d;
    }

    .ll-language-picker__items {
        :first-child {
            background: #0E0E10;

            :after {
                background: #0E0E10;
                border-right: 1px solid #515151;
                border-top: 1px solid #515151;
            }

            &.ll-dropdown-menu-item--active {
                :after {
                    border-right: 1px solid #515151;
                    border-top: 1px solid #515151;
                }
            }
        }
    }
}

.ll-language-picker--tablet,
.ll-language-picker--mobile {
    @extend %G-ll-language-picker--desktop;
    @extend %G-ll-language-picker--mobile;

    .ll-language-picker__wrapper {
        background: transparent;
        color: #fff;
    }

    .ll-dropdown-menu-item,
    .ll-dropdown-menu-item--active {
        background: #F5F5F5;
        border: 1px solid #C5C5C5;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.5. MODAL
|--------------------------------------------------------------------------
*/

.fullscreen-enabled {
    .ll-modal__scrollbox--desktop {
        max-width: 110vw;
    }
}

.ll-modal {
    @extend %G-ll-modal;

    background: #DADAD9;

    &:has(.ll-scrollbox--desktop) {
        // TODO: add box-shadow only on opened modal
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    }

    .ll-modal__header {
        background: #405337;
        color: #fff;
    }

    .ll-modal__header--error {
        background: -webkit-linear-gradient(45deg, #ff5a00, #fa762e);
        color: #fff;
    }

    .ll-modal__btns--external {
        .ll-modal__button {
            background: #405337;
            color: #fff;
        }
    }

    .ll-modal__button-inner {
        color: #dadad9;
    }

    .ll-modal__close-button {
        border-radius: 50%;
    }

    .ll-modal__fullscreen-button .ll-modal__button-inner {
        border-radius: 50%;
    }

    .ll-modal__custom-content {
        background-color: #fff;
    }
}

.ll-modal__yes-no {
    @extend %G-modal__yes-no;
}


.ll-modal__external-mobile-wrp,
.ll-game-modal {
    @extend %G-ll-game-modal;
}

.ll-game-error-modal {
    @extend %G-ll-game-error-modal;
}

.body--with-cursor {
    .cashout-modal__btn {
        border-color: #cd5b1d;
        background: #cd5b1d;
        color: #fff;
    }

    .ll-modal__yes-no .ll-btn--outlined:hover {
        border-color: #405337;
        background: #405337;
        color: #fff;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.5.1 MODAL DESKTOP
|--------------------------------------------------------------------------
*/

.ll-modal__fullscreen-button {
    top: -3.2rem;
    right: 4.3rem;
}

.ll-modal.ll-modal--desktop {
    @extend %G-ll-modal--desktop;
    background: #393939;

    &.luckyland-link {
        max-width: 70vw;

        .ll-modal__scrollbox--external {
            width: 70vw;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 2.5.2 MODAL MOBILE
|--------------------------------------------------------------------------
*/

.ll-modal--mobile {
    @extend %G-ll-modal--mobile;

    .promo-modal {
        min-width: 80vw;
    }

    .ll-modal__custom-content {
        border: 1px solid #DADAD9;
    }
}

.body--small-desktop {
    .ll-game-modal {
        @extend %G-ll-game-modal--small-desktop;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6. DRAWER
|--------------------------------------------------------------------------
*/

.ll-drawer {
    @extend %G-ll-drawer;
    background-color: rgba(255, 255, 255, 0.85);

    @supports (-webkit-backdrop-filter: blur()) {
        background-color: rgba(255, 255, 255, 0.9);
    }

    &.desktop {
        background-color: rgba(134, 134, 134, 0.53);

        @supports (-webkit-backdrop-filter: blur()) {
            background-color: rgba(134, 134, 134, 0.6);
        }
    }

    .ll-drawer__header--mobile {
        background-color: #888888;
        color: #fff;
    }

    .signup-drawer__header {
        background-color: #393939;
        color: #fff;
    }

    .mobile-drawer__close-btn {
        color: #fff;
    }

    .ll-drawer__btn {
        background: #637858;
        color: #fff;
    }

    .ll-drawer__btn.ll-btn--outlined {
        background: transparent;
        color: #cd5b1d;
        border-color: #cd5b1d;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.1 GAME MENU DRAWER
|--------------------------------------------------------------------------
*/

.drawer-menu {
    @extend %G-drawer-menu;
    color: #000;

    .drawer-menu__item-icon {
        color: #000;
    }

    .drawer-menu__item {
        border-bottom: 1px solid #2d2d2d;
        color: #000;
    }

    .menu-item__label {
        color: #000;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.2 SUPPORT MENU DRAWER
|--------------------------------------------------------------------------
*/

.support-menu {
    @extend %G-support-menu;
    color: #000;

    .support-menu__top-icon,
    .support-menu__icon,
    .llg-envelope-o,
    .ll-footer__social-icon {
        color: #cd5b1d;
    }

    .support-menu__item {
        border-bottom: 1px solid #1a1a1a;
        color: #000;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.3 MY ACCOUNT MENU DRAWER
|--------------------------------------------------------------------------
*/

.my-account-menu-drawer {
    @extend %G-my-account-menu-drawer;
    color: #000;

    .my-account-menu-drawer__icon {
        color: #fff !important;
    }

    .my-account-menu-drawer__blocked-user {
        color: #cd5b1d;
    }

    .my-account-menu-drawer__text--accent {
        color: #cd5b1d;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.4 LOGIN DRAWER
|--------------------------------------------------------------------------
*/

.login-drawer {
    @extend %G-login-drawer;

    .login-drawer-menu {
        color: #000;
    }

    .login-drawer-menu__item {
        background-color: #F5F5F5;
        border: 1px solid #DADAD9;
        color: #000;
    }

    .login-drawer-menu__item-name {
        border-right: 1px solid #DADAD9;
    }

    .login-drawer-menu__item-icon {
        color: #637858;
    }

    .mobile-drawer__close-btn {
        color: #000;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.6.4 MY BETS DRAWER
|--------------------------------------------------------------------------
*/
.my-bets-drawer {
    @extend %G-my-bets-drawer;
}

/*
|--------------------------------------------------------------------------
| == 2.7. POPOVER
|--------------------------------------------------------------------------
*/

.ll-popover {
    @extend %G-ll-popover;
}

.ll-popover-wrapper {
    @extend %G-ll-popover-wrapper;
}

.change-password__popover {
    @extend %G-change-password__popover;
}

.bet-history__status-filter-dropdown {
    @extend %G-bet-history__status-filter-dropdown;
}

.ll-footer__popover {
    @extend %G-ll-footer__popover;
}

.ll-footer__popover.ll-popover--tablet,
.ll-footer__popover.ll-popover--mobile {
    @extend %G-ll-footer__popover--tablet--mobile;

    background-color: #393939;

    .ll-footer__popover-item {
        color: #fff;

        &:not(:last-of-type) {
            border-bottom: 1px solid #2D2D2D;
        }
    }
}

.ll-footer__popover.ll-popover--desktop {
    @extend %G-ll-footer__popover--desktop;

    .ll-footer__popover-content {
        background-color: #405337;
    }

    .ll-footer__popover-item {
        color: #fff;

        &:not(:last-of-type) {
            border-bottom: 1px solid #2D3628;
        }
    }
}

.body--with-cursor {
    .ll-footer__popover-item:hover {
        color: #CD5B1D;
    }
}

.ll-popover__text {
    border: 1px solid #2d2d2d;
    border-radius: 3px;
    color: #000;
    background: #fff;
    padding: 0.3rem;
}

/*
|--------------------------------------------------------------------------
| == 2.8. BACKDROP
|--------------------------------------------------------------------------
*/

.ll-backdrop {
    @extend %G-ll-backdrop;

    background: transparent;
    transition: background .2s ease-out;

    &.ll-backdrop--open {
        background: rgba(0, 0, 0, 0.6);

        &.ll-backdrop--transparent {
            background: transparent;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == DISABLE OF USER SELECTS
|--------------------------------------------------------------------------
*/
.ll-dropdown-menu-item {
    user-select: none;
}

/*
|--------------------------------------------------------------------------
| == ACTIVE BETS & MY BETS DESKTOP POPOVER
|--------------------------------------------------------------------------
*/

.ll-popover-cashout_yes-no {
    @extend %G-popover-cashout_yes-no;

    .popup__text {
        font-size: 16px;
    }

    .popup__btns {
        .popup-btn {
            background: #cd5b1d;
            color: #fff;

            &:hover {
                box-shadow: inset 0 0 1px 1px #d06a39;
                background: transparent;
                color: #d06a39;
            }
        }

        .popup-btn--loading {
            box-shadow: inset 0 0 1px 1px #d06a39;
            background: transparent;
        }

        .popup-btn-outlined {
            box-shadow: inset 0 0 1px 1px #d06a39;
            background: transparent;
            color: #d06a39;

            &:hover {
                background: #cd5b1d;
                color: #fff;
            }
        }
    }

    .popup__btns--delete {
        .popup-btn {
            background: #ff0000;
            color: #fff;

            &:hover {
                box-shadow: inset 0 0 1px 1px #ff0000;
                background: transparent;
                color: #ff0000;
            }
        }

        .popup-btn-outlined {
            box-shadow: inset 0 0 1px 1px #ff0000;
            background: transparent;
            color: #ff0000;

            &:hover {
                background: #ff0000;
                color: #fff;
            }
        }
    }
}
