
.ll-widget-item {
    &.ll-widget-item--sports {
        background-color: #2D2D2D;
        color: #fff;
    }

    &[class*='ll-widget-item--home-'],
    &.ll-widget-item--sports-banner {
        color: #fff;
    }

    // ~~~~~~ EVENT WIDGET ~~~~~~~
    .ll-widget-item__bet {
        background-color: #708e61;
        color: #fff;
    }

    .ll-widget-item__bet--active {
        background-color: #cd5b1d;
    }

    .ll-widget-item__dropdown {
        border: 1px solid #7c7c7c;
        color: #fff;

        &.ll-dropdown-wrapper--opened {
            background-color: #cd5b1d;
            border-color: #cd5b1d;
        }
    }

    .ll-widget-item__bets--bet-builder {
        .doruk-dots {
            border: 1px solid #888;
        }
    }

    // ~~~~~~ GAME WIDGET ~~~~~~~
    .ll-widget-item__play-button {
        background-color: #708e61;
    }

    // ~~~~~~ JACKPOT WIDGET ~~~~~~~
    .jackpot-widget-details__label--grand {
        color: #ff6d1e;
    }

    .jackpot-widget-details__label--major {
        color: #759d61;
    }

    .jackpot-widget-details__label-value {
        color: #fff;
    }

    .jackpot-widget-details__label-value--grand {
        background-color: #cd5b1d;
    }

    .jackpot-widget-details__label-value--major {
        background-color: #708e61;
    }

    .jackpot-widget-details__label--minor,
    .jackpot-widget-details__label--mini {
        color: #759d61;
    }

    .jackpot-widget__info--small {
        .jackpot-widget-details__label-value {
            box-shadow: inset 0 0 1px 1px #759d61;
        }
    }
}

// ~~~~~~ DROPDOWN CONTENT ~~~~~~~

.ll-widget-item-dropdown-content {
    background-color: #393939;
    color: #fff;

    .ll-dropdown-menu-item--active:not(:last-of-type),
    .ll-dropdown-menu-item:not(:last-of-type) {
        border-bottom: 1px solid #7c7c7c;
    }

    .ll-widget-item-dropdown-content__bet-value {
        color: #cd5b1d;
    }

    .ll-dropdown-menu-item--active {
        background-color: #708e61;
        color: #fff;

        .ll-widget-item-dropdown-content__bet-value {
            color: #fff;
        }
    }
}

// ~~~~~~ BETBUILDER BETS ~~~~~~~

.ll-widget-item-overflowed-content {
    @extend %G-ll-widget-item-overflowed-content;

    background-color: #393939;
    color: #FFFFFF;

    .ll-widget-item__bets-item--bet-builder {
        &::before {
            box-shadow: 0 0 0 2px #393939;
        }
    }
}

.ll-widget-item__bets-item--bet-builder {
    &::before {
        box-shadow: 0 0 0 2px #2D2D2D;
        background-color: #888888;
    }

    &::after {
        border-left: 1px solid #888888;
    }
}


.ll-sport-widget__slider .slider__item {
    &:not(:first-of-type) {
        border-left: 2px solid #515151;
    }

    &:first-of-type {
        border-left: 1px solid #515151;
    }

    &:last-of-type {
        border-right: 1px solid #515151;
    }
}

.body--with-cursor {
    .ll-widget-item-dropdown-content {
        .ll-dropdown-menu-item:hover,
        .ll-dropdown-menu-item--active:hover {
            background-color: #708E61;
            color: #fff;

            .ll-widget-item-dropdown-content__bet-value {
                color: #fff;
            }
        }
    }

    .ll-widget-item__bet:hover,
    .ll-widget-item__play-button:hover {
        background-color: #CD5B1D;
    }

    .ll-widget-item__bets-content--bet-builder {
        &.ll-widget-item__bets-content--bet-builder--overflowed:hover {
            color: #CD5B1D;
            background-color: transparent;

            .ll-widget-item__bets-item--bet-builder {
                &::before {
                    box-shadow: 0 0 0 2px #2D2D2D;
                }
            }
        }
    }

    .jackpot-widget-details__label-value--grand:hover {
        background-color: #ff6d1e;
    }

    .jackpot-widget-details__label-value--major:hover {
        background-color: #cd5b1d;
    }

    .jackpot-widget-details__label-value--minor:hover,
    .jackpot-widget-details__label-value--mini:hover {
        box-shadow: none;
        background-color: #759d61;
    }
}