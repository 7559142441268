.pre-match-details__market-group__custom-bets {
    background: #405337;
}

.ll-custom-bets {
    .ll-custom-bets__info {
        background-color: #40533745;
    }

    .ll-custom-bets__about-image {
        background-image: url("../../../../static/themes/theme/images/custom-bets/custom-bets.png");
    }

    .ll-custom-bets__title {
        color: #fff;
        background: #405337;
    }

    .ll-custom-bets__about-terms-link {
        color: #cd5b1d;
    }

    .ll-custom-bets__close-info {
        background-color: #cd5b1d;
        color: white;

        &:after {
            border-top: 5px solid #cd5b1d;
        }
    }

    .ll-custom-bets__market-groups {
        background-color: #637858;
    }

    .ll-custom-bets__open-close-info {
        color: #fff;
    }

    .custom-market-group > .match-details__market-header {
        background-color: #405337;
    }

    .ll-custom-bets-pre__market-group--active {
        color: #cd5b1d;
    }

    .match-details__market-header {
        background-color: #393939;
        color: #fff;
    }

    .match-details__bet--custom-unavailable {
        opacity: .5;
        cursor: not-allowed;
    }
}

.ll-scrollbox--tablet .ll-custom-bets,
.ll-scrollbox--desktop .ll-custom-bets {
    .fancy-bets-slider-wrp {
        border-top: 1px solid #393939;
    }

    .match-details__bets {
        border: 1px solid #393939;
        border-top: none;
    }

    .match-details__bet {
        background: #888888;
        color: #fff;

        &.match-details__bet--active {
            background: #cd5b1d;
            color: #fff;

            .match-details__bet-value {
                color: #fff;
            }
        }

        &.bet--column2:nth-child(2n+1):not(:only-child) {
            box-shadow: inset -1px 0 0 0 #393939;
        }

        &.bet--column3:last-child {
            box-shadow: inset -1px 0 0 0 #888888;
        }

        &.bet--column3:not(:last-child) {
            box-shadow: inset -1px 0 0 0 #393939;
        }
    }
}

.ll-scrollbox--mobile .ll-custom-bets {
    @extend %G-custom-bets--mobile;

    .ll-custom-bets__about-text {
        padding-bottom: 3rem;
    }

    .ll-custom-bets__info {
        background: #DADAD9;
    }

    .ll-custom-bets__title {
        border-radius: unset;
    }

    .custom-market-group > .match-details__market-header {
        background-color: #405337;
    }

    .match-details__market {
        background-color: #191919;
    }

    .match-details__market-header {
        background-color: #303030;
        border: none;
    }

    .match-details__bets {
        border-color: #303030;
    }

    .match-details__bet {
        background-color: #393939;
        color: #fff;

        &.match-details__bet--active {
            background: #cd5b1d;
            color: #fff;
        }

        &.bet--column2:nth-child(2n+1):not(:only-child) {
            box-shadow: inset -2px 0 0 0 #303030;
        }

        &.bet--column3 {
            box-shadow: inset -2px 0 0 0 #303030;
        }
    }

    .match-details__bet--custom-unavailable {
        opacity: 1;
        span {
            opacity: .4;
        }
    }
}

.body--with-cursor .ll-custom-bets {
    .ll-custom-bets__about-terms-link:hover {
        color: #cd5b1d;
    }

    .ll-custom-bets__open-close-info:hover {
        color: #cd5b1d;
    }

    .pre-match-details__market-group:hover {
        color: #cd5b1d;
        background: transparent!important;
    }

    .ll-custom-bets__bet-button:hover {
        background-color: #cd5b1d;
        color: #fff;
    }

    .match-details__bet:not(.match-details__bet--disabled):hover {
        background: #cd5b1d;
        color: #fff;
    }

    .ll-custom-bets__about-info-button:hover {
        color: #CD5B1D;
    }
}

.bet-builder-modal__info-header {
    color: #cd5b1d;
}

.ll-custom-bets__about-info-container {

    .ll-custom-bets__about-info-button {
        color: #FFFFFF;
    }

    .ll-custom-bets__about-info-content-container {
        color: #2d2d2d;
        background-color: #F5F5F5;
        border-color: #9da499;
    }

    .ll-custom-bets__about-info-close {
        color: #7c7c7c;
    }
}
