/*
3.PAGES
└── 3.2. SIGNUP PAGE
   ├── 3.2.1 SIGNUP DESKTOP
   ├── 3.2.2 SIGNUP TABLET
   └── 3.2.3 SIGNUP MOBILE
*/

/*
|--------------------------------------------------------------------------
| == 3.2. SIGNUP PAGE
|--------------------------------------------------------------------------
*/

.ll-input__pwdStrength {
    @extend %G-ll-input__pwdStrength;

    &.weak {
        color: red
    }

    &.perfect {
        color: green;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.1. SIGNUP DESKTOP
|--------------------------------------------------------------------------
*/

.signup--desktop,
.signup--tablet {
    @extend %G-signup--desktop;
}

.signup--desktop,
.signup--tablet,
.signup--mobile {
    .ll-input label.ll-input__label--inside,
    .signup__currencies .signup__field-label {
        color: #000;
    }

    .dx-radiobutton {
        box-shadow: inset 0 0 0 1px #DADAD9;
    }

    .signup__sumbit-btn {
        background-color: #637858;
        color: #fff;
    }

    .signup__form-header {
        background-color: #888888;
        color: #fff;
    }

    .ll-input__label,
    .signup__field-label,
    .password-strength__label {
        color: #fff;
    }

    .signup__container {
        background-color: #fff;
    }

    .signup__errors {
        color: #000;

        .dx-item:hover {
            .dx-item-content {
                color: #cd5b1d;
            }
        }

        .dx-item-content {
            color: #000;

            &:before {
                color: #cd5b1d;
            }
        }
    }

    .signup__gender {
        .dx-collection {
            color: #000;
        }
    }

    .dx-radiobutton-icon:before {
        border: 2px solid #CD5B1D;
    }

    .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
        background: #CD5B1D;
    }

    .signup__currencies-symbol {
        color: #000;
    }

    .signup__phone-flag-container {
        color: #000;
        border-right: 1px solid #DADAD9;
    }

    .signup__footer-link {
        color: #cd5b1d;
    }

    .signup__footer-text {
        color: #fff;
    }

    .signup__footer-eighteen--check {
        .dx-checkbox-icon {
            border: 2px solid #e87a4b;
            background: none;
            color: #000;
        }
    }

    .dx-texteditor-container {
        &:focus-within {
            box-shadow: 0 0 2px 1px rgba(97, 192, 189, 0.5);
        }
    }

    .dx-texteditor-input-container {
        box-shadow: 0 0 2px 1px #dadad9;
    }

    .dx-texteditor.dx-state-focused {
        box-shadow: 0 0 2px 1px rgba(97, 192, 189, 0.5);
    }

    .dx-texteditor.dx-editor-underlined {
        box-shadow: 0 0 2px 1px #dadad9;

        &:focus-within {
            box-shadow: 0 0 2px 1px rgba(97, 192, 189, 0.5);
        }
    }

    .signup__login-btn {
        background: transparent;
        color: #ff5A00;
    }

    .dx-radiobutton,
    .signup__currency-picker,
    .signup__country-picker {
        box-shadow: inset 0 0 0 1px #DADAD9;
        color: #2D2D2D;
        background-color: white;
    }

    .dx-invalid-message > .dx-overlay-content,
    .signup__footer-eighteen--error {
        color: #C73738;
    }
}

.signup--desktop,
.signup--tablet {

    .signup__container {
        background-color: #2D2D2D;
        color: #fff;
    }

    .ll-input label.ll-input__label--inside,
    .signup__currencies .signup__field-label {
        color: #fff;
    }

    .dx-radiobutton {
        box-shadow: inset 0 0 0 1px #515151;
    }

    .signup__form-header {
        background-color: #515151;
        color: #fff;
    }

    .form__captcha-link,
    .signup__footer-link {
        color: #FF6D1E;
    }

    .dx-invalid-message > .dx-overlay-content,
    .signup__footer-eighteen--error {
        color: #DD4444;
    }

    .ll-input__label,
    .signup__field-label,
    .password-strength__label {
        color: #fff;
    }

    .signup__errors {
        color: #fff;

        .dx-item-content {
            color: #fff;
        }
    }

    .signup__gender {
        .dx-collection {
            color: #fff;
        }
    }

    .signup__currencies-symbol {
        color: #fff;
    }

    .signup__phone-flag-container {
        color: #fff;
        border-right: 1px solid #515151;
    }

    .signup__footer-eighteen {
        color: #fff;
    }

    .signup__footer-eighteen--check {
        .dx-checkbox-icon {
            color: #fff;
        }
    }

    .dx-texteditor-input-container {
        box-shadow: 0 0 2px 1px #515151;
    }

    .dx-texteditor.dx-editor-underlined {
        box-shadow: 0 0 2px 1px #515151;
    }

    .dx-radiobutton,
    .signup__currency-picker,
    .signup__country-picker {
        box-shadow: inset 0 0 0 1px #515151;
        color: #fff;
        background-color: #2D2D2D;

        &.dx-texteditor.dx-editor-outlined.dx-state-hover,
        &.dx-texteditor.dx-editor-filled.dx-state-hover {
            box-shadow: inset 0 0 0 1px #515151;
        }
    }

    .dx-texteditor-input {
        color: #fff;
    }

    .ll-input:not(.coupons__amount-input):not(.games__search-input) {

        .dx-texteditor {
            box-shadow: inset 0 0 0 1px #515151;
            color: #fff;
            background-color: #2D2D2D;
        }

        input.dx-texteditor-input,
        input.dx-texteditor-input:-webkit-autofill {
            color: #fff !important;
            -webkit-text-fill-color: #fff !important;
        }

        &.ll-input--focused,
        &.ll-input--labeled-up {
            .ll-input__label:not(.ll-input__label--top) {
                background: linear-gradient(180deg, transparent 55%, #2D2D2D 45%);
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.2 SIGNUP TABLET
|--------------------------------------------------------------------------
*/

.signup--tablet {
    @extend %G-signup--tablet;
}

/*
|--------------------------------------------------------------------------
| == 3.2.3 SIGNUP MOBILE
|--------------------------------------------------------------------------
*/

.signup--mobile {
    @extend %G-signup--mobile;
    color: #000;

    .signup__container {
        background: transparent;
    }

    .signup__footer-eighteen,
    .form__captcha-text {
        box-shadow: inset 0 0 0 1px #DADAD9;
        color: #2D2D2D;
        background-color: white;
    }

    .signup__country-picker,
    .signup__currency-picker,
    .ll-input__text-box-container {
        input {
            color: #2d2d2d;
        }
    }

    .signup__header--mobile {
        background-color: #888888;
        color: #fff;
    }
}
