/*
3.PAGES
└── 3.10. HELP PAGE
*/

/*
|--------------------------------------------------------------------------
| == 3.10. HELP PAGE
|--------------------------------------------------------------------------
*/

.help {
    @extend %G-help;

    color: #fff;
    
    .help__tile {
        background: url('../../../../static/themes/theme/images/help/live_help.png') no-repeat 50% 50%;
        background-size: contain;
    }
}

.help-mobile {
    @extend %G-help-mobile;
}